import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../Utils/useContext Hooks/CartContextComponent";

const OrderInformation = () => {

  const { cartItems } = useContext(CartContext);
  const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const newTotalPrice = cartItems.reduce((total, item) => {
            const price = parseInt(item.productPrice.replace(/\D/g, ''));
            return total + (price * item.quantity);
        }, 0);
        setTotalPrice(newTotalPrice);
    }, [cartItems])

  return (
    <>
      <div className="cart-order-informations mx-5 px-5 pt-5 pb-5">
        <h3 className="border-bottom pb-3">Вашата нарачка</h3>
        <div className="pt-3 pb-3 border-bottom">
          {cartItems &&
            cartItems.map((item) => (
              <ul
                className="d-flex justify-content-between"
                key={item.productCode}
              >
                <li className="size-20 pb-3">{item.productNameMk}</li>
                <li className="size-20 pb-3">
                  {parseInt(item.productPrice.replace(/\D/g, "")) *
                    item.quantity}{" "}
                  ден
                </li>
              </ul>
            ))}
        </div>
        <p className="pt-3 size-19 fw-bold dark-orange-color text-end">
          Вкупна цена: {totalPrice} ден
        </p>
      </div>
    </>
  );
};

export default OrderInformation;
