
import { BsCartX } from "react-icons/bs";

const EmptyCart = () => {
    return (
        <>
            <div className="container" style={{background: "#f8f7ff"}}>
                <div className="row">
                    <div className="col-md-12 text-center pt-5 pb-5">
                        <BsCartX style={cartIconStyle}/>
                        <h3 className="mt-3 fw-bold">Вашата кошничка е празна</h3>
                    </div>
                </div>
            </div>
        </>
    )
}


const cartIconStyle = {
    "fontSize": "2rem"
}

export default EmptyCart;