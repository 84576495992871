import { useContext, useRef, useState } from "react";
import { sendEmailToOwnerCart } from "../../Utils/App Functions/appFunctions";
import Button from "../Button/Button";
import { CartContext } from "../../Utils/useContext Hooks/CartContextComponent";


const CartFormMalo = () => {

    const { cartItems, setCartItems } = useContext(CartContext);
    const [error, setError] = useState();
    const nameRef = useRef();
    const surnameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const addressRef = useRef();

    return (
        <>
            <form>
                    <div className="mb-3">
                        <label htmlFor="inputName" className="form-label size-20">Внесете име</label>
                        <input type="text" className="form-control" id="inputName" ref={nameRef}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputSurname" className="form-label size-20">Внесете презиме</label>
                        <input type="text" className="form-control" id="inputSurname" ref={surnameRef}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputEmail" className="form-label size-20">Внесете Е-Маил адреса</label>
                        <input type="email" className="form-control" id="inputEmail" ref={emailRef}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputPhone" className="form-label size-20">Внесете телефонски број</label>
                        <input type="text" className="form-control" id="inputPhone" ref={phoneRef}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputAddress" className="form-label size-20">Внесете адреса</label>
                        <input type="text" className="form-control" id="inputAddress" ref={addressRef}/>
                    </div>
                    {
                error ? <p className="text-danger size-18 fw-bold">{error}</p> : ``
            }
                    <Button text="Постави нарачка" classes="mt-3" onClick={() => sendEmailToOwnerCart(nameRef.current.value,
                surnameRef.current.value,
                emailRef.current.value,
                phoneRef.current.value,
                addressRef.current.value,
                setCartItems,
                setError)}/>
                    </form>
        </>
    )
}

export default CartFormMalo;