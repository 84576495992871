import './categorieslineupstyle.css';
import dataConfig from '../../Utils/Data/data-config';
import { Link } from "react-router-dom";

const CategoriesLineup = () => {

    return (
        <>
            <section className='pt-5 pb-5 categories-lineup-section'>
                <div className="container">
                    <div className="row text-center">
                    {
                        dataConfig.map((item) => (
                                 <div className="col-md-4">
                                     <Link to={`/category/${item.category.replace(/\s/g, '-').toLowerCase()}/${item.id}`}>
                                    <div className="category-box pt-5" style={{background: `url(${item.lineupImage})`, backgroundSize: "cover"}}>
                                        <h2 style={{fontSize: '1.7rem'}}>{item.lineupTitle}</h2>
                                        <p className='size-20'>{item.lineupDescription}</p>
                                    </div>
                                    </Link>
                                </div>
                        ))
                    }
                    </div>
                </div>
            </section>
        </>
    )
}

export default CategoriesLineup;