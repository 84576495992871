
import firstHeroHomeImage1 from '../../Assets/Images/hero-section-icon-1.png';
import firstHeroHomeImage2 from '../../Assets/Images/hero-section-icon-2.png';
import firstHeroHomeImage3 from '../../Assets/Images/hero-section-icon-3.png';

import secondHomeSectionImage1 from '../../Assets/Images/information-section-icon-1.png';
import secondHomeSectionImage2 from '../../Assets/Images/information-section-icon-2.png';
import secondHomeSectionImage3 from '../../Assets/Images/information-section-icon-3.png';

export const HeroSectionInfo = [
    {
        id: 1,
        title: `Премиум квалитет`,
        desc: `Нудиме козметика со извонреден квалитет`,
        image: firstHeroHomeImage1
    },
    {
        id: 2,
        title: `Перфекција`,
        desc: `Услуга што ја заслужувате.`,
        image: firstHeroHomeImage2
    },
    {
        id: 3,
        title: `Врвен Избор`,
        desc: `Изберете го најдоброто - изберете ги нашите производи.`,
        image: firstHeroHomeImage3
    },
]

export const informationSectionInfo = [
    {
        id: 1,
        title: `Бесплатна испорака`,
        desc: `Нудиме бесплатна испорака над 3.000 денари`,
        image: secondHomeSectionImage1
    },
    // {
    //     id: 2,
    //     title: `Замена`,
    //     desc: `Нудиме замена за одредени производи`,
    //     image: secondHomeSectionImage2
    // },
    {
        id: 3,
        title: `Подршка`,
        desc: `Достапни сме да ви помогнеме`,
        image: secondHomeSectionImage3
    },
]