import './productslineupstyle.css';
import ProductCard from '../Product Card/ProductCard';
import { useState } from 'react';
import { productsLineupList } from '../../Utils/Data/data-config';
import { Link } from 'react-router-dom';

const ProductsLineup = () => {

    const [productsList, setProductsList] = useState(productsLineupList);

    return (
        <>
            <section className="pt-5 pb-5 products-lineup-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="products-lineup-top d-flex justify-content-between align-items-center">
                                <h3 className="title-text fw-bold">Најдобри производи</h3>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                    {
                            productsList.map((item) => (
                               <div className='col-md-3'>
                                    <Link to={item.productLink}>
                                        <ProductCard props={item}/>
                                    </Link>
                                </div>
                            ))
                           }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductsLineup;