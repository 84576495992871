import Navigation from "../../Components/Navigation/Navigation";
import '../../Assets/Style/singlecategorystyle.css';
import Footer from "../../Components/Footer/Footer";
import { Link, useParams } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import dataConfig from "../../Utils/Data/data-config";

const SingleCategoryPage = () => {

    const { id } = useParams();

    const [categoryData, setCategoryData] = useState();
    const [subCategoryData, setSubCategoryData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const categoryData = await dataConfig.find((item) => item.id == id);
                setCategoryData(categoryData);
                setSubCategoryData(categoryData.subCategory);
            }
            catch (error){
                console.log(error)
            }
        }
        fetchData();
    }, [id])

    if(!categoryData) { return null; }

    return (
        <>
            <Navigation/>
            <section className="single-category-section">
                <div className="container pt-5 pb-5">
                    <div className="row text-center flex-column justify-content-center align-items-center">
                        <div className="col-md-6">
                            <h1 className="single-category-title">{categoryData.lineupTitle}</h1>
                        </div>

                        <div className="col-md-6 pt-5 w-100">
                            <img src={categoryData.imageTop} alt="" className="w-100 single_category_top_image"/>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="row justify-content-center">
                        {
                        subCategoryData.map((item) => (
                                 <div className="col-md-4 mt-3 mb-3" key={item.id}>
                                     <Link to={`${item.nameEn.replace(/\s/g, '-').toLowerCase()}/${item.id}`}>
                                    <div className="pt-5 text-center pb-5 bg-white">
                                        <img src={item.image} className="w-100" alt=""/>
                                        <h2 style={{fontSize: '1.7rem'}}>{item.name}</h2>
                                    </div>
                                    </Link>
                                </div>
                        ))
                    }
                    </div>
                </div>
            
            </section>

            <Footer/>
        </>
    )
}

export default SingleCategoryPage;