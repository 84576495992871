import './footerstyle.css';
import { BiLogoFacebook, BiLogoInstagram } from "react-icons/bi";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="footer-section pt-4 pb-3 bg-black text-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <div className='footer-box'>
                                <h2 className='footer-title'>Контакт</h2>
                                <p>Доколку ви е потребна помош, обратете се на sprofessionalmk@yahoo.com</p>
                                <ul className='d-flex gap-3 footer-social-links mt-3'>
                                    <li><Link to="https://www.facebook.com/p/Selective-Professional-Macedonia-100082994839438/?paipv=0&eav=AfYeitq1p2gGqMWzFFKd9UzFj09X3GoU4QX8NLWY09GcDe68GaCRPuMQzR1XJWVj6JU&_rdr"><BiLogoFacebook/></Link></li>
                                    <li><Link to="https://www.instagram.com/selectiveprofessional_mk/"><BiLogoInstagram/></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='footer-box'>
                                <h2 className='footer-title'>Нашата локација</h2>
                                <p>ул.24 бр.32, Скопје</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='footer-box'>
                                <h2 className='footer-title'>Работно време</h2>
                                <p>Понеделник – Петок: 8:00 – 16:00</p>
                            </div>
                        </div>
                    </div>

                    <div className='row text-center'>
                        <p>Copyright © 2023 <Link to="https://cloudytechnologies.mk/">Cloudytechnologies</Link>. Сите права се задржани.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;