import CategoriesLineup from "../../Components/Categories Lineup/CategoriesLineup";
import Footer from "../../Components/Footer/Footer";
import HeroSection from "../../Components/Hero Section/HeroSection";
import HomeTopSection from "../../Components/Home Top Section/HomeTopSection";
import Navigation from "../../Components/Navigation/Navigation";
import ProductSpotlight from "../../Components/Product Spotlight/ProductSpotlight";
import ProductsLineup from "../../Components/Products Lineup/ProductsLineup";
import TestimonialsSection from "../../Components/Testimonials Section/TestimonialsSection";
import { HeroSectionInfo, informationSectionInfo } from "../../Utils/Hero Section Config/hero-section-config";


const HomePage = () => {

    // HSH = Hero Section Home
    // ISH = Information Section Home

    return (
        <>
            <Navigation/>
            <HomeTopSection/>
            <HeroSection value={HeroSectionInfo} type="HSH"/>
            <CategoriesLineup/>
            <ProductsLineup/>
            <HeroSection value={informationSectionInfo} type="ISH"/>
            <ProductSpotlight/>
            <TestimonialsSection/>
            <Footer/>
        </>
    )
}

export default HomePage;