import './navigationstyle.css';
import { AiOutlineShoppingCart } from "react-icons/ai";
import logo from '../../Assets/Images/logo.png';
import { Link } from "react-router-dom";


const Navigation = () => {
    return (
        <>
            {/* <nav className='navigation-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 navigation-container'>
                            <ul className='d-flex justify-content-between align-items-center'>
                                <li><Link to="/"><img src={logo} alt='' className='navigation-logo'/></Link></li>
                                <ul className='navigation-middle-container d-flex gap-5'>
                                    <li><Link to="/">Дома</Link></li>
                                    <li><Link to="/category/technical-products/1">Технички Продукти</Link></li>
                                    <li><Link to="/category/treatments/2">Третмани</Link></li>
                                    <li><Link to="/category/styling/3">Стилови</Link></li>
                                    <li><Link to="/about-us">За Нас</Link></li>
                                    <li><Link to="/bulk-cart">Нарачај големо</Link></li>
                                </ul>
                                <ul className='d-flex gap-4 navigation-icons-container'>
                                    <li><Link to="/cart"><AiOutlineShoppingCart/></Link></li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav> */}

            <nav className="navbar navbar-expand-lg navbar-light bg-black navigation-section">
                <div className="container">
                    <Link to="/"><img src={logo} alt='' className='navigation-logo'/></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-around" id="navbarNav">
                        <ul className="navbar-nav navigation-middle-container d-flex gap-5">
                            <li><Link to="/">Дома</Link></li>
                            <li><Link to="/category/technical-products/1">Технички Продукти</Link></li>
                            <li><Link to="/category/treatments/2">Третмани</Link></li>
                            <li><Link to="/category/styling/3">Стилизирање</Link></li>
                            <li><Link to="/about-us">За Нас</Link></li>
                            <li><Link to="/bulk-cart">Нарачај големо</Link></li>
                        </ul>
                        <ul className='d-flex gap-4 navigation-icons-container'>
                            <li><Link to="/cart"><AiOutlineShoppingCart/></Link></li>
                            </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navigation;