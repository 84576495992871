import technicalProductsTopImage from '../../Assets/Images/Single Category Page Images/technical-products-category-top.webp';
import technicalProductsLineupImage from '../../Assets/Images/technical-products-category-bg.jpg';
import treatmentsTopImage from '../../Assets/Images/Single Category Page Images/treatments-category-top.jpg';
import treatmentsLineupImage from '../../Assets/Images/treatments-category-bg.jpg';
import stylingTopImage from '../../Assets/Images/Single Category Page Images/styling-category-top.png';
import stylingLineupImage from '../../Assets/Images/styling-category-bg.jpg';


/* Technical Products Category backgrounds for Sub Categories */

import bojaZaKosaHidrogenBg from '../../Assets/Images/Single Category Page Images/boja-za-kosa-hidrogen-bg.png';
import blanshBg from '../../Assets/Images/Single Category Page Images/blansh-bg.png';
import pigmentiBg from '../../Assets/Images/Single Category Page Images/pigmenti-bg.png';
import reversoBg from '../../Assets/Images/Single Category Page Images/reverso-bg.png';


/* Technical Products Images */

import colorevo from '../../Assets/Images/Products Images/colorevo.png';
import colorevoOxy from '../../Assets/Images/Products Images/colorevo-oxy.png';
import powerplex from '../../Assets/Images/Products Images/powerplex.png';
import decolorvitPlusBusta from '../../Assets/Images/Products Images/decolorvit-plus-busta.png';
import decolorvitScalpBusta from '../../Assets/Images/Products Images/decolorvit-scalp-busta.png';
import decolorvitActiveUse from '../../Assets/Images/Products Images/decolorvit-active-use.png';
import pigmentsTheBlue from '../../Assets/Images/Products Images/pigments-the-blue.png';
import pigmentsTheCopper from '../../Assets/Images/Products Images/pigments-the-copper.png';
import pigmentsTheRed from '../../Assets/Images/Products Images/pigments-the-red.png';
import pigmentsTheViolet from '../../Assets/Images/Products Images/pigments-the-violet.png';
import pigmentsTheGrey from '../../Assets/Images/Products Images/pigments-the-grey.png';
import reversoToner from '../../Assets/Images/Products Images/reverso-toner.png';
import reversoOxy from '../../Assets/Images/Products Images/reverso-oxy.png';
import reversoRevealer from '../../Assets/Images/Products Images/reverso-revealer.png';


/* Treatments Category backgrounds for Sub Categories */

import hempSublimeBg from '../../Assets/Images/Single Category Page Images/hempsublime-bg.png';
import damagedHairRisanaBg from '../../Assets/Images/Single Category Page Images/damaged-hair-risana-bg.png';
import onCareTherapyOnlyBg from '../../Assets/Images/Single Category Page Images/on-care-therapy-only-bg.png';
import curlyHairBg from '../../Assets/Images/Single Category Page Images/curly-hair-bg.png';
import colorBlockBg from '../../Assets/Images/Single Category Page Images/color-block-bg.png';
import blondHairNoYellowBg from '../../Assets/Images/Single Category Page Images/blond-hair-no-yellow-bg.png';
import onCareScalpDefenseBg from '../../Assets/Images/Single Category Page Images/on-care-scalp-defense-bg.png';
import onCareLossDefenseBg from '../../Assets/Images/Single Category Page Images/on-care-loss-defense-bg.png';
import onCareRefillBg from '../../Assets/Images/Single Category Page Images/on-care-refill-bg.png';
import onCareSmoothBg from '../../Assets/Images/Single Category Page Images/on-care-smooth-bg.png'
import allInOneBg from '../../Assets/Images/Single Category Page Images/all-in-one-bg.png';
import avocadoShotsBg from '../../Assets/Images/Single Category Page Images/avocado-shots-bg.webp';
import rebuildingBg from '../../Assets/Images/Single Category Page Images/rebuilding-bg.png';
import artisticFlairBg from '../../Assets/Images/Single Category Page Images/artistic-flair-bg.png';
import onCareRepairBg from '../../Assets/Images/Single Category Page Images/on-care-repair-bg.png';


/* Styling Category backgrounds from Sub Categories */

import stylingBg from '../../Assets/Images/Single Category Page Images/styling-bg.png';


/* Treatments Products Images */

import hempSublimeShampoo from '../../Assets/Images/Products Images/hemp-sublime-shampoo.png';
import hempSublimeShampoo1L from '../../Assets/Images/Products Images/hemp-sublime-shampoo.png';
import hempSublimeConditioner1L from '../../Assets/Images/Products Images/hemp-sublime-conditioner-1000.jpg';
import hempSublimeConditioner250ml from '../../Assets/Images/Products Images/hemp-sublime-conditioner.png';
import hempSublimeConditioner from '../../Assets/Images/Products Images/hemp-sublime-conditioner.png';
import hempSublimeElixir from '../../Assets/Images/Products Images/hemp-sublime-elixir.png';
import hempSublimeMask from '../../Assets/Images/Products Images/hemp-sublime-mask.png';
import risanaShampoo from '../../Assets/Images/Products Images/risana-shampoo.png';
import risanaAcquaOil from '../../Assets/Images/Products Images/risana-acqua-oil.png';
import risanaLeaveInSpray from '../../Assets/Images/Products Images/risana-leave-in-spray.png';
import hydrationShampoo from '../../Assets/Images/Products Images/hydration-shampoo.png';
import dailyShampoo1L from '../../Assets/Images/Products Images/daily-shampoo-1000.png';
import dailyConditioner250 from '../../Assets/Images/Products Images/daily-conditioner-250.jpg';
import dailyConditioner1000 from '../../Assets/Images/Products Images/daily-conditioner-1000.jpg';
import hydrationConditioner from '../../Assets/Images/Products Images/hydration-conditioner.png';
import dailyLeaveIn from '../../Assets/Images/Products Images/daily-leave-in.png';
import curlyLoverShampoo from '../../Assets/Images/Products Images/curly-lover-shampoo.png';
import curlyLoverConditioner from '../../Assets/Images/Products Images/curly-lover-conditioner.png';
import colorBlockShampoo from '../../Assets/Images/Products Images/color-block-shampoo.png';
import colorBlockConditioner from '../../Assets/Images/Products Images/color-block-conditioner.png';
import noYellowShampoo from '../../Assets/Images/Products Images/no-yellow-shampoo.png';
import noYellowConditioner from '../../Assets/Images/Products Images/no-yellow-conditioner.png';
import dandruffControlShampoo from '../../Assets/Images/Products Images/dandruff-control-shampoo.png';
import dandruffControlLotion from '../../Assets/Images/Products Images/dandruff-control-lotion.png';
import reduceShampoo from '../../Assets/Images/Products Images/reduce-shampoo.png';
import stimulateShampoo from '../../Assets/Images/Products Images/stimulate-shampoo.png';
import stimulateLotion from '../../Assets/Images/Products Images/stimulate-lotion.png';
import densiFillShampoo from '../../Assets/Images/Products Images/densi-fill-shampoo.png';
import densiFillTreatment from '../../Assets/Images/Products Images/densi-fill-treatment.png';
import densiFillFastFoam from '../../Assets/Images/Products Images/densi-fill-fast-foam.png';
import smoothMilk from '../../Assets/Images/Products Images/smooth-milk.png';
import smoothConditioner from '../../Assets/Images/Products Images/smooth-conditioner.png';
import smoothShampoo from '../../Assets/Images/Products Images/smooth-shampoo.png';
import allInOneSpray from '../../Assets/Images/Products Images/all-in-one-spray.png';
import allInOneColorSpray from '../../Assets/Images/Products Images/all-in-one-color-spray.png';
import avocadoShot from '../../Assets/Images/Products Images/avocado-shot.png';
import duoPhasette from '../../Assets/Images/Products Images/duo-phasette.png';
import pearlShampoo from '../../Assets/Images/Products Images/pearl-shampoo.png';
import greenAppleShampoo from '../../Assets/Images/Products Images/green-apple-shampoo.png';
import midolloDiBambooShampoo from '../../Assets/Images/Products Images/midollo-di-bamboo-shampoo.png';
import keratinShampoo from '../../Assets/Images/Products Images/keratin-shampoo.png';
import keratinConditioner from '../../Assets/Images/Products Images/keratin-conditioner.png';
import keratinMask from '../../Assets/Images/Products Images/keratin-mask.png';
import hairConditioner from '../../Assets/Images/Products Images/hair-conditioner.png';
import repairShampoo from '../../Assets/Images/Products Images/repair-shampoo.png';
import repairBalm from '../../Assets/Images/Products Images/repair-balm.png';
import chelatingShampoo from '../../Assets/Images/Products Images/chelating-shampoo.png';
import rebuildTreatment from '../../Assets/Images/Products Images/rebuild-treatment.png';
import chelatingSpray from '../../Assets/Images/Products Images/chelating-spray.png';

/* Styling Products Image */

import pumpToo from '../../Assets/Images/Products Images/pump-too.png';
import designCurl from '../../Assets/Images/Products Images/design-curl.png';
import shapeStrong from '../../Assets/Images/Products Images/shape-strong.png';
import excelStrong from '../../Assets/Images/Products Images/excel-strong.png';
import mattMolder from '../../Assets/Images/Products Images/matt-molder.png';
import lightOn from '../../Assets/Images/Products Images/light-on.png';
import powderVol from '../../Assets/Images/Products Images/powder-vol.png';
import fastCreate from '../../Assets/Images/Products Images/fast-create.png';
import silkyWay from '../../Assets/Images/Products Images/silky-way.png';
import upto230 from '../../Assets/Images/Products Images/up-to-230.png';


// Additional Photos to use */

import chelatingProductUse from '../../Assets/Images/chelating-product-use.jpg';



const dataConfig = [
    {
        id: 1,
        category: `Technical Products`,
        lineupTitle: 'Технички продукти',
        lineupDescription: 'иновативни и креативни технички линии дизајнирани за фризери',
        lineupImage: technicalProductsLineupImage,
        imageTop: technicalProductsTopImage,
        subCategory: [
            {
                id: 1,
                name: `Боја за коса и хидроген`,
                nameEn: `colorevo`,
                image: bojaZaKosaHidrogenBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Colorevo",
                        productNameMk: "Боја за коса",
                        productDescription: "Сензационални резултати, полно тело и здрава коса благодарение на драгоцените состојки кои делуваат во секоја фаза од процесот на боење. COLOREVO нуди високо персонализиран и приспособлив концепт на боја. Пријатен мирис и за корисникот и за купувачот. Во мешавина со COLOREVO MIX, 5 чисти нијанси и 2 неутрални формули, овозможува да се неутрализираат несаканите светлини за да се создадат неограничени бои.",
                        productUse: `COLOREVO е дизајниран да дејствува во синергија со COLOREVO OXY 10-20-30 Vol (3%, 6%, 9%)
                        Соодносот на мешање е 1+1 [пр.: 100 ml COLOREVO + 100 ml COLOREVO OXY].
                        Во смесата со COLOREVO MIX, додадете ја соодветната количина според избраните нијанси: за посветли нијанси додадете помала количина COLOREVO MIX. За нијанси како 9-10, препорачливо е да додадете 5% на вкупниот крем.
                        Нанесете ја на сува и неисчистена коса.
                        
                        Време на одмор: 30 минути.`,
                        productPrice: "500ден",
                        productCode: "1384001",
                        productImage: colorevo,
                        additionalOptions: true,
                    }, 
                    {
                        id: 2,
                        productNameEn: "Colorevo Oxy",
                        productNameMk: "Хидроген 3% 1000мл",
                        productDescription: `Специфично дизајниран да добие впечатливи резултати со сите нијанси на COLOREVO. Синергијата помеѓу производите гарантира: прилепување на смесата, целосна покриеност на седата коса, максимална сјајност и долготрајни бои, совршен развој на дејството на избелување и намален ризик од иритации. Цветен мирис, во склад со мирисот COLOREVO.
                        Достапно во 4 варијации: 10, 20, 30, 40 волумени.`,
                        productUse: `
                        За да постигнете најдобри резултати со COLOREVO, препорачуваме да се повикате на информациите подолу:
VOL. % ФУНКЦИЈА
10 3 За затемнување, за осветлување до 1 тон или тон во тон
20 6 Целосно покривање на седата коса, за потемна боја или за осветлување 1-2 тона
30 9 За покривање на седата коса со осветлување 2-3 тони
40 12 За осветлување 4-5 тона со COLOREVO BLOND, за осветлување 4 тона со COLOREVO GLITCH`,
                        productPrice: "500ден",
                        productCode: "1385010",
                        productImage: colorevoOxy,
                        additionalOptions: true,
                    },
                    {
                        id: 3,
                        productNameEn: "Colorevo Oxy",
                        productNameMk: "Хидроген 6% 1000мл",
                        productDescription: `Специфично дизајниран да добие впечатливи резултати со сите нијанси на COLOREVO. Синергијата помеѓу производите гарантира: прилепување на смесата, целосна покриеност на седата коса, максимална сјајност и долготрајни бои, совршен развој на дејството на избелување и намален ризик од иритации. Цветен мирис, во склад со мирисот COLOREVO.
                        Достапно во 4 варијации: 10, 20, 30, 40 волумени.`,
                        productUse: `
                        За да постигнете најдобри резултати со COLOREVO, препорачуваме да се повикате на информациите подолу:
VOL. % ФУНКЦИЈА
10 3 За затемнување, за осветлување до 1 тон или тон во тон
20 6 Целосно покривање на седата коса, за потемна боја или за осветлување 1-2 тона
30 9 За покривање на седата коса со осветлување 2-3 тони
40 12 За осветлување 4-5 тона со COLOREVO BLOND, за осветлување 4 тона со COLOREVO GLITCH`,
                        productPrice: "500ден",
                        productCode: "1385020",
                        productImage: colorevoOxy,
                        additionalOptions: true,
                    },
                    {
                        id: 4,
                        productNameEn: "Colorevo Oxy",
                        productNameMk: "Хидроген 9% 1000мл",
                        productDescription: `Специфично дизајниран да добие впечатливи резултати со сите нијанси на COLOREVO. Синергијата помеѓу производите гарантира: прилепување на смесата, целосна покриеност на седата коса, максимална сјајност и долготрајни бои, совршен развој на дејството на избелување и намален ризик од иритации. Цветен мирис, во склад со мирисот COLOREVO.
                        Достапно во 4 варијации: 10, 20, 30, 40 волумени.`,
                        productUse: `
                        За да постигнете најдобри резултати со COLOREVO, препорачуваме да се повикате на информациите подолу:
VOL. % ФУНКЦИЈА
10 3 За затемнување, за осветлување до 1 тон или тон во тон
20 6 Целосно покривање на седата коса, за потемна боја или за осветлување 1-2 тона
30 9 За покривање на седата коса со осветлување 2-3 тони
40 12 За осветлување 4-5 тона со COLOREVO BLOND, за осветлување 4 тона со COLOREVO GLITCH`,
                        productPrice: "500ден",
                        productCode: "1385030",
                        productImage: colorevoOxy,
                        additionalOptions: true,
                    },
                    {
                        id: 5,
                        productNameEn: "Colorevo Oxy",
                        productNameMk: "Хидроген 12% 1000мл",
                        productDescription: `Специфично дизајниран да добие впечатливи резултати со сите нијанси на COLOREVO. Синергијата помеѓу производите гарантира: прилепување на смесата, целосна покриеност на седата коса, максимална сјајност и долготрајни бои, совршен развој на дејството на избелување и намален ризик од иритации. Цветен мирис, во склад со мирисот COLOREVO.
                        Достапно во 4 варијации: 10, 20, 30, 40 волумени.`,
                        productUse: `
                        За да постигнете најдобри резултати со COLOREVO, препорачуваме да се повикате на информациите подолу:
VOL. % ФУНКЦИЈА
10 3 За затемнување, за осветлување до 1 тон или тон во тон
20 6 Целосно покривање на седата коса, за потемна боја или за осветлување 1-2 тона
30 9 За покривање на седата коса со осветлување 2-3 тони
40 12 За осветлување 4-5 тона со COLOREVO BLOND, за осветлување 4 тона со COLOREVO GLITCH`,
                        productPrice: "500ден",
                        productCode: "1385040",
                        productImage: colorevoOxy,
                        additionalOptions: true,
                    }
                ]
            },
            {
                id: 2,
                name: `Бланш`,
                nameEn: `decolorvit`,
                image: blanshBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Powerplex",
                        productNameMk: "Бланш со заштита 500 гр",
                        productDescription: "Осветленост, убавина и заштита во едно решение. Благодарение на иновативниот шеќерен комплекс со високо кондиционирачки, навлажнувачки и заштитни својства, Bleaching Powder гарантира максимална заштита на влакната на косата. Обезбедува униформно и редовно белење, до 8 нивоа на осветлување, уште блескаво и посветло благодарение на анти-жолтиот пигмент.",
                        productUse: `
                        За да се гарантираат извонредни резултати, се препорачува да се користи Бланш со заштита исклучиво во мешавина со неговиот активатор Powerplex Oxy 10-20-30-40 vol.

СТАНДАРД ЗА ТЕКСТУРА
1:2
ПРИЛАГОДНА ТЕКСТУРА
1:1 или 1:1,5 во зависност од саканата текстура и од техничкиот сервис
ВРЕМЕ НА ОБРАБОТКА 45 минути`,
                        productPrice: "1800ден",
                        productCode: "1370645",
                        productImage: powerplex,
                    },
                    {
                        id: 2,
                        productNameEn: "Decolorvit Plus Busta",
                        productNameMk: "Син бланш 7 нивоа 500 гр",
                        productDescription: `Прашок за белење со ексклузивна „безбедна салонска формула“ компактна, со нулта испарливост, идеален за супер-осветлување и брзо и униформно дејство. Гарантира униформни резултати и на природната и на обоена коса. DECOLORVIT plus, измешан исклучиво со DECOLORVIT активна употреба, овозможува избелување до 7 тони, со целосно почитување на остатоците од структурата на косата. Лесна за мешање и нанесување, смесата е мазна, униформа и компактна. Неговата сина боја помага да се неутрализираат топлите светлини.
                        Мирис: јаболко.`,
                        productUse: `
                        DECOLORVIT Plus треба да се меша исклучиво со DECOLORVIT Active Use 10-20-30-40 vol. врз основа на посакуваното ниво на белење. Измешајте го производот и нанесете на сува коса. Оставете да одмори, но проверете го процесот на белење на секои 5 минути, до максимум 55 минути. Кога ќе заврши времето за одмор, исплакнете темелно со вода и исчистете со специфичен шампон од ONcare Color Care.

СООДНОС НА МЕШАЊЕ
со Decolorvit Active Use 10-20-30 вол

Стандардна текстура: 1+2 [на пр.: 30 гр DECOLORVIT плус + 60 ml DECOLORVIT активна употреба 10-20-30-40 vol.] Се препорачува за нагласување и фолија.
Персонализирана текстура: 1+1 [на пр.: 30 гр DECOLORVIT плус + 30 ml DECOLORVIT активен користи 10, 20, 30 или 40 тома] за нагласување со помош на чешел.
Време на развој: варира во зависност од обемот на DECOLORVIT Active Use 10-20-30-40 vol. и на структурата на косата што треба да се обработи.

Максимално време 55 минути.`,
                        productPrice: "1050ден",
                        productCode: "1370160",
                        productImage: decolorvitPlusBusta
                    },
                    {
                        id: 3,
                        productNameEn: "Decolorvit Scalp Busta",
                        productNameMk: "Виолетов бланш 6 нивоа 500 гр",
                        productDescription: `Неиспарлив прашок за белење, со ексклузивна „безбедна салонска формула“, специјално дизајниран за избелување на скалпот и за нагласување со фолии. Овозможува постигнување на високи нивоа на белење со целосна безбедност и целосно почитување на структурата на косата. Помешано со активна употреба на DECOLORVIT, постепено ја осветлува косата до 6 тони. Неговата виолетова боја помага да се неутрализираат несаканите жолто-бакарни тонови. Лесно се блендира при подготовка и нанесување. Под никакви услови не капе и не набабрува, останува мек долго време, без да се исуши.
                        Мирис: овошно-цветна мешавина.`,
                        productUse: `DECOLORVIT Скалпот треба да се меша исклучиво со DECOLORVIT Active Use 10-20-30 вол. врз основа на посакуваното ниво на белење. Измешајте и нанесете на сува коса. Оставете да одмори, но проверете го процесот на белење на секои 5 минути, до максимум 35 минути за нанесување на скалпот и 60 минути за нанесување на длабочина на косата. Времето за одмор зависи од изборот на волумени за активна употреба на DECOLORVIT и структурата на третираната коса. Кога ќе заврши времето за одмор, добро исплакнете со вода и исчистете со специфичен шампон од линијата ONcare Color Care производи.

                        СООДНОС НА МЕШАЊЕ
                        со Decolorvit Active Use 10-20-30 vol, 1+1 [на пр.: 30 g DECOLORVIT скалп + 1 ml DECOLORVIT Active Use 10-20-30 vol.]
                        
                        Максимално време на развој:
                        35 минути за нанесување на скалпот
                        60 минути за нанесување на вратилото на косата`,
                        productPrice: "1050ден",
                        productCode: "1370161",
                        productImage: decolorvitScalpBusta
                    },
                    {
                        id: 4,
                        productNameEn: "Decolorvit Active Use",
                        productNameMk: "Виолетов хидроген 6% 750 мл",
                        productDescription: `Неиспарлив прашок за белење, со ексклузивна „безбедна салонска формула“, специјално дизајниран за избелување на скалпот и за нагласување со фолии. Овозможува постигнување на високи нивоа на белење со целосна безбедност и целосно почитување на структурата на косата. Помешано со активна употреба на DECOLORVIT, постепено ја осветлува косата до 6 тони. Неговата виолетова боја помага да се неутрализираат несаканите жолто-бакарни тонови. Лесно се блендира при подготовка и нанесување. Под никакви услови не капе и не набабрува, останува мек долго време, без да се исуши.
                        Мирис: овошно-цветна мешавина.`,
                        productUse: `DECOLORVIT Скалпот треба да се меша исклучиво со DECOLORVIT Active Use 10-20-30 вол. врз основа на посакуваното ниво на белење. Измешајте и нанесете на сува коса. Оставете да одмори, но проверете го процесот на белење на секои 5 минути, до максимум 35 минути за нанесување на скалпот и 60 минути за нанесување на длабочина на косата. Времето за одмор зависи од изборот на волумени за активна употреба на DECOLORVIT и структурата на третираната коса. Кога ќе заврши времето за одмор, добро исплакнете со вода и исчистете со специфичен шампон од линијата ONcare Color Care производи.

                        СООДНОС НА МЕШАЊЕ
                        со Decolorvit Active Use 10-20-30 vol, 1+1 [на пр.: 30 g DECOLORVIT скалп + 1 ml DECOLORVIT Active Use 10-20-30 vol.]
                        
                        Максимално време на развој:
                        35 минути за нанесување на скалпот
                        60 минути за нанесување на вратилото на косата`,
                        productPrice: "550ден",
                        productCode: "1370141",
                        productImage: decolorvitActiveUse
                    },
                    {
                        id: 5,
                        productNameEn: "Decolorvit Active Use",
                        productNameMk: "Виолетов хидроген 9% 750 мл",
                        productDescription: `Неиспарлив прашок за белење, со ексклузивна „безбедна салонска формула“, специјално дизајниран за избелување на скалпот и за нагласување со фолии. Овозможува постигнување на високи нивоа на белење со целосна безбедност и целосно почитување на структурата на косата. Помешано со активна употреба на DECOLORVIT, постепено ја осветлува косата до 6 тони. Неговата виолетова боја помага да се неутрализираат несаканите жолто-бакарни тонови. Лесно се блендира при подготовка и нанесување. Под никакви услови не капе и не набабрува, останува мек долго време, без да се исуши.
                        Мирис: овошно-цветна мешавина.`,
                        productUse: `DECOLORVIT Скалпот треба да се меша исклучиво со DECOLORVIT Active Use 10-20-30 вол. врз основа на посакуваното ниво на белење. Измешајте и нанесете на сува коса. Оставете да одмори, но проверете го процесот на белење на секои 5 минути, до максимум 35 минути за нанесување на скалпот и 60 минути за нанесување на длабочина на косата. Времето за одмор зависи од изборот на волумени за активна употреба на DECOLORVIT и структурата на третираната коса. Кога ќе заврши времето за одмор, добро исплакнете со вода и исчистете со специфичен шампон од линијата ONcare Color Care производи.

                        СООДНОС НА МЕШАЊЕ
                        со Decolorvit Active Use 10-20-30 vol, 1+1 [на пр.: 30 g DECOLORVIT скалп + 1 ml DECOLORVIT Active Use 10-20-30 vol.]
                        
                        Максимално време на развој:
                        35 минути за нанесување на скалпот
                        60 минути за нанесување на вратилото на косата`,
                        productPrice: "550ден",
                        productCode: "1370142",
                        productImage: decolorvitActiveUse
                    },
                ]
            },
            {
                id: 3,
                name: "Пигменти",
                nameEn: "pigments",
                image: pigmentiBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Pigments The Blue",
                        productNameMk: "Син пигмент 80мл",
                        productDescription: `Чистите пигменти погодни за сите типови коса, природни и обоени, можат да создадат повеќе бои и нијанси, да ги поправат несаканите светлини, да ја вратат светлината и да ја воздигнат живоста на бојата помеѓу едната и другата услуга и, во исто време, да имате коса која е неверојатно здрава, мека и сјајна.
                        the Blue идеален за персонализирање на природните семејства, пепел, беж и фантазија, особено за правење на потемните тонови поладни. На руса или изветвена коса создава луди сини нијанси.
                        `,
                        productUse: `
                        Пигментите може да се користат во салонот, во синергија со оксидирачко боење, COLOREVO или REVERSO HAIR COLOR, или со третмани за хидратација и реструктуирање ONcare, Hemp Sublime, Pearl Sublime. Кога ја подготвувате бојата/маската, додајте ги ПИГМЕНТИ во смесата и продолжете со нанесувањето како и обично. Времето за одмор не се менува.

Пропорцијата на мешавина со боја
ДЛАБОЧИНИ НА БОЈА – ПРОПОРЦИИ – ПРИМЕР

1/2/3/4/5 – 20:1 – 60 ml мешавина на бои: 3 ml сино
6/7 – 30:1 – 60 ml мешавина на бои: 2 ml сино
8 – 60:1 – 60 ml мешавина на бои: 1 ml сино
9/10 – 60:0,5 – 60 ml мешавина на бои: 0,5 ml сино
Процентот на мешавина со третмани за убавина ONcare, Hemp Sublime, Pearl Sublime
ПРОПОРЦИИ – ПРИМЕР

20:1 – 20 ml маска/регенератор: 1 ml сино
Траење на бојата со третмани за убавина: 3 до 5 перења`,
                        productPrice: "1050ден",
                        productCode: "1310000",
                        productImage: pigmentsTheBlue
                    },
                    {
                        id: 2,
                        productNameEn: "Pigments The Copper",
                        productNameMk: "Бакарен пигмент 80мл",
                        productDescription: "Чистите пигменти погодни за сите типови коса, природни и обоени, можат да создадат повеќе бои и нијанси, да ги поправат несаканите светлини, да ја вратат светлината и да ја воздигнат живоста на бојата помеѓу едната и другата услуга и, во исто време, да имате коса која е неверојатно здрава, мека и сјајна. theCOPPER ги возвишува сите тоналитети на бакар, ги интензивира и персонализира златните, црвените и фантастичните семејства. Дури и на најобоена коса може да се дофатат сите нијанси на бакар, дури и до луд бакар.",
                        productUse: `
                        Пигментите може да се користат во салонот, во синергија со оксидирачко боење, COLOREVO или REVERSO HAIR COLOR, или со третмани за хидратација и реструктуирање ONcare, 
                        Hemp Sublime, Pearl Sublime. Кога ја подготвувате бојата/маската, додајте ги ПИГМЕНТИ во смесата и продолжете со нанесувањето како и обично. 
                        Времето за одмор не се менува.

                        Пропорцијата на мешавина со боја
                        ДЛАБОЧИНИ НА БОЈА – ПРОПОРЦИИ – ПРИМЕР
                        
                        1/2/3/4/5 – 20:1 – 60 ml мешавина на бои: 3 ml бакар
                        6/7 – 30:1 – 60 ml мешавина на бои: 2 ml БАКАР
                        8 – 60:1 – 60 ml мешавина на бои: 1 ml БАКАР
                        9/10 – 60:0,5 – 60 ml мешавина на бои: 0,5 ml БАКАР
                        Процентот на мешавина со третмани за убавина ONcare, Hemp Sublime, Pearl Sublime
                        ПРОПОРЦИИ – ПРИМЕР
                        
                        20:1 – 20 ml маска/регенератор: 1 ml бакар
                        Траење на бојата со третмани за убавина: 3 до 5 перења`,
                        productPrice: "1050ден",
                        productCode: "1310002",
                        productImage: pigmentsTheCopper
                    },
                    {
                        id: 3,
                        productNameEn: "Pigments The Red",
                        productNameMk: "Црвен пигмент 80мл",
                        productDescription: `Чистите пигменти погодни за сите типови коса, природни и обоени, можат да создадат повеќе бои и нијанси, да ги поправат несаканите светлини, да ја вратат светлината и да ја воздигнат живоста на бојата помеѓу едната и другата услуга и, во исто време, да имате коса која е неверојатно здрава, мека и сјајна.
                        theRED ги интензивира семејствата на црвените, бакарните и виолетовите, ги враќа црвените кои станаа досадни и избледени, овозможува обезбојуваната коса да достигне секоја нијанса на црвена, дури и до лудо црвена.`,
                        productUse: `Пигментите може да се користат во салонот, во синергија со оксидирачко боење, COLOREVO или REVERSO HAIR COLOR, или со третмани за хидратација и реструктуирање ONcare, Hemp Sublime, Pearl Sublime. Кога ја подготвувате бојата/маската, додајте ги ПИГМЕНТИ во смесата и продолжете со нанесувањето како и обично. Времето за одмор не се менува.

                        Пропорцијата на мешавина со боја
                        ДЛАБОЧИНИ НА БОЈА – ПРОПОРЦИИ – ПРИМЕР
                        
                        1/2/3/4/5 – 20:1 – 60 ml мешавина на бои: 3 ml таму
                        6/7 – 30:1 – 60 ml мешавина на бои: 2 ml таму
                        8 – 60:1 – 60 ml мешавина на бои: 1 ml таму
                        9/10 – 60:0,5 – 60 ml мешавина на бои: 0,5 ml таму
                        Процентот на мешавина со третмани за убавина ONcare, Hemp Sublime, Pearl Sublime
                        ПРОПОРЦИИ – ПРИМЕР
                        
                        20:1 – 20 ml маска/регенератор: 1 ml таму ЦРВЕНО
                        Траење на бојата со третмани за убавина: 3 до 5 перења`,
                        productPrice: "1050ден",
                        productCode: "1310003",
                        productImage: pigmentsTheRed
                    },
                    {
                        id: 4,
                        productNameEn: "Pigments The Violet",
                        productNameMk: "Виолетов пигмент 80мл",
                        productDescription: `Чистите пигменти погодни за сите типови коса, природни и обоени, можат да создадат повеќе бои и нијанси, да ги поправат несаканите светлини, да ја вратат светлината и да ја воздигнат живоста на бојата помеѓу едната и другата услуга и, во исто време, да имате коса која е неверојатно здрава, мека и сјајна.
                        ВИОЛЕТА ги зачувува и интензивира семејствата на виолетова, махагони, фантазија, ги персонализира црвените семејства, ги разладува полесните тоналитети на фамилиите од пепел. На руса или изветвена коса создава луди виолетови нијанси.`,
                        productUse: `Пигментите може да се користат во салонот, во синергија со оксидирачко боење, COLOREVO или REVERSO HAIR COLOR, или со третмани за хидратација и реструктуирање ONcare, Hemp Sublime, Pearl Sublime. Кога ја подготвувате бојата/маската, додајте ги ПИГМЕНТИ во смесата и продолжете со нанесувањето како и обично. Времето за одмор не се менува.

                        Пропорцијата на мешавина со боја
                        ДЛАБОЧИНИ НА БОЈА – ПРОПОРЦИИ – ПРИМЕР
                        
                        1/2/3/4/5 – 20:1 – 60 ml мешавина на бои: 3 ml ВИОЛЕТКА
                        6/7 – 30:1 – 60 ml мешавина на бои: 2 ml ВИОЛЕТА
                        8 – 60:1 – 60 ml мешавина на бои: 1 ml ВИОЛЕТ
                        9/10 – 60:0,5 – 60 ml мешавина на бои: 0,5 ml ВИОЛЕТА
                        Процентот на мешавина со третмани за убавина ONcare, Hemp Sublime, Pearl Sublime
                        ПРОПОРЦИИ – ПРИМЕР
                        
                        20:1 – 20 ml маска/регенератор: 1 ml ВИОЛЕТ
                        Траење на бојата со третмани за убавина: 3 до 5 перења`,
                        productPrice: "1050ден",
                        productCode: "1310005",
                        productImage: pigmentsTheViolet
                    },
                    {
                        id: 5,
                        productNameEn: "Pigments The Grey",
                        productNameMk: "Сив пигмент 80мл",
                        productDescription: `Чистите пигменти погодни за сите типови коса, природни и обоени, можат да создадат повеќе бои и нијанси, да ги поправат несаканите светлини, да ја вратат светлината и да ја воздигнат живоста на бојата помеѓу едната и другата услуга и, во исто време, да имате коса која е неверојатно здрава, мека и сјајна.
                        ВИОЛЕТА ги зачувува и интензивира семејствата на виолетова, махагони, фантазија, ги персонализира црвените семејства, ги разладува полесните тоналитети на фамилиите од пепел. На руса или изветвена коса создава луди виолетови нијанси.`,
                        productUse: `Пигментите може да се користат во салонот, во синергија со оксидирачко боење, COLOREVO или REVERSO HAIR COLOR, или со третмани за хидратација и реструктуирање ONcare, Hemp Sublime, Pearl Sublime. Кога ја подготвувате бојата/маската, додајте ги ПИГМЕНТИ во смесата и продолжете со нанесувањето како и обично. Времето за одмор не се менува.

                        Пропорцијата на мешавина со боја
                        ДЛАБОЧИНИ НА БОЈА – ПРОПОРЦИИ – ПРИМЕР
                        
                        1/2/3/4/5 – 20:1 – 60 ml мешавина на бои: 3 ml ВИОЛЕТКА
                        6/7 – 30:1 – 60 ml мешавина на бои: 2 ml ВИОЛЕТА
                        8 – 60:1 – 60 ml мешавина на бои: 1 ml ВИОЛЕТ
                        9/10 – 60:0,5 – 60 ml мешавина на бои: 0,5 ml ВИОЛЕТА
                        Процентот на мешавина со третмани за убавина ONcare, Hemp Sublime, Pearl Sublime
                        ПРОПОРЦИИ – ПРИМЕР
                        
                        20:1 – 20 ml маска/регенератор: 1 ml ВИОЛЕТ
                        Траење на бојата со третмани за убавина: 3 до 5 перења`,
                        productPrice: "1050ден",
                        productCode: "1310006",
                        productImage: pigmentsTheGrey
                    },
                ]
            },
            {
                id: 3,
                name: "Reverso",
                nameEn: "reverso",
                image: reversoBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Reverso Toner",
                        productNameMk: "Тонер без амонијак 100мл",
                        productDescription: `Ги униформира, воздигнува и ревитализира акцентите и третманите за избелување. На фризурите им дава максимална козметичност и сјај, засилувајќи го сјајот и сјајот на рефлексите. Благодарение на екстремно високата концентрација на антиоксиданси, витамини и минерали, го подобруваат резултатот на бојата. Без амонијак и PPD.
                        Достапен во 7 нијанси: бисер, сива, песок, ирис, тутун, кајсија, праска.`,
                        productUse: `Нијансите на REVERSO TONER треба да се користат во мешавина со REVERSO REVEALER за тонирање или неутрализирање на осветлените и третманите за избелување.
                        Соодносот на мешањето е 1:2.
                        Време на одмор:
                        10 мин за неутрализирање
                        20 мин до тон`,
                        productPrice: "600ден",
                        productCode: "1389960",
                        productImage: reversoToner,
                        additionalOptions: true,
                    },
                    {
                        id: 2,
                        productNameEn: "Reverso Revealer",
                        productNameMk: "Активатор 1,5% 1000мл",
                        productDescription: `Специфичен откривач создаден за да гарантира најдобри резултати во синергија со REVERSO HAIR COLOR за да се добие интензивен и брилијантен тон на тонови бои. Неговата специјална адитивна формула овозможува изведување на полутрајни бои од тон-во-тон.`,
                        productUse: `За полу-постојана услуга во боја, тон-на-тон.
                        Сооднос на мешање 1:2
                        Измешајте 1 дел од REVERSO HAIR COLOR + 2 дел од REVERSO REVEALER и добро измешајте додека не се добие мазен крем.
                        Време на одмор:
                        20 мин
                        30 мин за поголем интензитет и подобра покриеност`,
                        productPrice: "600ден",
                        productCode: "1389995",
                        productImage: reversoRevealer,
                        additionalOptions: true,
                    },
                    {
                        id: 3,
                        productNameEn: "Reverso Oxy",
                        productNameMk: "Хидроген 6% 1000мл",
                        productDescription: `Формула специјално развиена за да гарантира најдобри резултати во синергија со Reverso Hair Color. REVERSO OXY е деликатна оксидирачка емулзија збогатена со ексклузивна мешавина на екстракти од свежа суперхрана.`,
                        productUse: `Нијансите на REVERSO HAIR COLOR треба да се користат во мешавина со REVERSO OXY 10-20-30-40 vol.

                        Сооднос на мешање:
                        1:1
                        1:2 со суперрусокоса
                        
                        Време на одмор:
                        30 мин
                        45 мин со REVERSO superblond
                        
                        Како да се користи:
                        
                        VOL. % ФУНЗИОН
                        10 3 тон на тон – потемна боја – белење 0-1 нијанса
                        20 6 покривање бела коса – colore più scuro – schiaritura 1-2 сенка
                        30 9 покривање бела коса – белење 2-3 нијанса
                        40 12 белење 4 нијанса со REVERSO superblond`,
                        productPrice: "600ден",
                        productCode: "1389992",
                        productImage: reversoOxy,
                        additionalOptions: true,
                    },
                ],
            },
        ]
    },
    {
        id: 2,
        category: `Treatments`,
        lineupTitle: 'Третмани',
        lineupDescription: 'третмани за убавина за сите типови коса',
        lineupImage: treatmentsLineupImage,
        imageTop: treatmentsTopImage,
        subCategory: [
            {
                id: 1,
                name: "Hemp Sublime",
                nameEn: "hemp sublime",
                image: hempSublimeBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Hemp Sublime Shampoo",
                        productNameMk: "Шампон за сува и слаба коса 1000мл",
                        productDescription: `Збогатен со масло од семе од коноп и со одлични хидратантни, негувачки и зајакнувачки својства, деликатно ја чисти косата, враќајќи ја нејзината хидролипидна рамнотежа. Ја остава косата свиленкаста и изгледа поздрава и полна со тело.`,
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со Ultimate Luxury Conditioner или Ultimate Luxury Mask, во зависност од нивото на дехидрација.`,
                        productPrice: "1050ден",
                        productCode: "1373734",
                        productImage: hempSublimeShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Hemp Sublime Shampoo",
                        productNameMk: "Шампон за сува и слаба коса 250мл",
                        productDescription: `Збогатен со масло од семе од коноп и со одлични хидратантни, негувачки и зајакнувачки својства, деликатно ја чисти косата, враќајќи ја нејзината хидролипидна рамнотежа. Ја остава косата свиленкаста и изгледа поздрава и полна со тело.`,
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со Ultimate Luxury Conditioner или Ultimate Luxury Mask, во зависност од нивото на дехидрација.`,
                        productPrice: "550ден",
                        productCode: "1373730",
                        productImage: hempSublimeConditioner250ml
                    },
                    {
                        id: 3,
                        productNameEn: "Hemp Sublime Conditioner",
                        productNameMk: "Кондиционер за сува и слаба коса 1000мл",
                        productDescription: "Збогатен со масло од семе од коноп и со одлични хидратантни, хранливи и зајакнувачки својства, интензивно ја уредува и отплеткува сувата и ослабена коса без да тежи, одржувајќи одлично и долготрајно ниво на хидратација.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја олесните дистрибуцијата. Оставете да одмори 1-2 минути. Исплакнете темелно и стилизирајте по потреба. Користете редовно за најдобри резултати. Завршете го третманот со примена на Еликсир.`,
                        productPrice: "1050ден",
                        productCode: "1373735",
                        productImage: hempSublimeConditioner1L
                    },
                    {
                        id: 4,
                        productNameEn: "Hemp Sublime Conditioner",
                        productNameMk: "Кондиционер за сува и слаба коса 200мл",
                        productDescription: "Збогатен со масло од семе од коноп и со одлични хидратантни, хранливи и зајакнувачки својства, интензивно ја уредува и отплеткува сувата и ослабена коса без да тежи, одржувајќи одлично и долготрајно ниво на хидратација.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја олесните дистрибуцијата. Оставете да одмори 1-2 минути. Исплакнете темелно и стилизирајте по потреба. Користете редовно за најдобри резултати. Завршете го третманот со примена на Еликсир.`,
                        productPrice: "550ден",
                        productCode: "1373731",
                        productImage: hempSublimeConditioner
                    },
                    {
                        id: 5,
                        productNameEn: "Hemp Sublime Elixir",
                        productNameMk: "Еликсир 100мл",
                        productDescription: "Збогатен со масло од семе од коноп и со одлични хидратантни, хранливи и зајакнувачки својства, ја отплеткува, ја зајакнува и измазнува кутикулата, обезбедувајќи еластичност, сјај и одличен сјај на длабочина на косата. Идеален и за секојдневна употреба.",
                        productUse: `Нанесете на чиста, исушена коса 1-2 дози по должината на вратилото и врвовите, а потоа исушете. Нанесете на сува коса за дополнително уредување и хидратација. За да го интензивирате хидратантното дејство на третманите Hemp Sublime, нанесете неколку капки пред шампонирање, оставајќи го да одмори неколку минути.`,
                        productPrice: "1200ден",
                        productCode: "1373733",
                        productImage: hempSublimeElixir
                    },
                    {
                        id: 6,
                        productNameEn: "Hemp Sublime Mask",
                        productNameMk: "Маска за потхрана и хидратација 250мл",
                        productDescription: "Збогатен со масло од семе од коноп, со високи хидратантни, хранливи и зајакнувачки својства, делува длабински, враќајќи го вистинското ниво на хидратација во значително сувите и кршливи структури на косата.",
                        productUse: `Нанесете на измиена и добро исушена коса. Исчешлајте. Оставете да делува 3-5 минути. Исплакнете внимателно и исушете. Користете 1-2 пати неделно, наизменично со Hemp Sublime Ultimate Luxury Conditioner врз основа на потребното ниво на хидратација. За подлабоко кондиционирање, измешајте со неколку капки Хемп Сублим Ултимејт Луксузен Еликсир.`,
                        productPrice: "650ден",
                        productCode: "1373732",
                        productImage: hempSublimeMask,
                    },
                ]
            }, 
            {
                id: 2,
                name: "Damaged Hair Risana",
                nameEn: "damaged hair risana",
                image: damagedHairRisanaBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Risana Shampoo",
                        productNameMk: "Шампон за реструктуирање 1000мл",
                        productDescription: "Нежен шампон специјално дизајниран за оштетена и напната коса. Длабоко ги храни влакната на косата и и дава одлично тело.",
                        productUse: `Нанесете на целата коса, почнувајќи од коренот. Масирајте нежно и исплакнете темелно. Повторете ако е потребно. Препорачуваме да го користите во комбинација со други производи од асортиманот Damaged Hair Risana.`,
                        productPrice: "1200ден",
                        productCode: "1312000",
                        productImage: risanaShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Risana Acqua + Oil",
                        productNameMk: "Двокомпонентна маска",
                        productDescription: "Инстант двокомпонентна маска за реструктуирање, специјално дизајнирана за оштетена и напната коса. Ја храни и реструктуира косата без да ја оптоварува, за посјаен, здрав и компактен изглед за видливост. Хидрира и го подобрува изгледот на кршливи и оштетени влакна на косата.",
                        productUse: `Измешајте ја содржината на RISANA 1 со RISANA 2 во сад и добро соединете за да добиете компактен и едноличен крем. Нанесете со специјална четка по целата коса и должина. Оставете да делува 1 до 5 минути, во зависност од типот на косата, а потоа исплакнете и стилизирајте по потреба. Препорачуваме да го користите во комбинација со други производи од асортиманот DAMAGED RISANA.`,
                        productPrice: "2400ден",
                        productCode: "1312042",
                        productImage: risanaAcquaOil
                    },
                    {
                        id: 3,
                        productNameEn: "Risana Leave In Spray",
                        productNameMk: "Спреј без измивање 150мл",
                        productDescription: "Двофазен спреј без плакнење за оштетена и напната коса со моментален ефект на реструктуирање. Заситен со слуз од полжав, ја храни косата, го олеснува расплетувањето и дава одлично тело и сјај.",
                        productUse: `Добро протресете пред употреба. Испрскајте ситно на чиста, исушена со пешкир или сува коса, од растојание од приближно 20 см. Исчешлајте за да ја фаворизирате рамномерната распределба. Исушете и стилизирајте по потреба, без плакнење. Препорачуваме да го користите во комбинација со други производи од асортиманот DAMAGED RISANA.`,
                        productPrice: "650ден",
                        productCode: "1312020",
                        productImage: risanaLeaveInSpray
                    },
                ]
            }, 
            {
                id: 3,
                name: "Daily",
                nameEn: "Daily",
                image: onCareTherapyOnlyBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Hydration Shampoo",
                        productNameMk: "Шампон за хидратација 1000мл",
                        productDescription: "Се препорачува шампон за сува или досадна коса. Ги чисти влакната на косата, оставајќи ја косата погуста, помека и со полно тело.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со најсоодветниот третман.`,
                        productPrice: "1450ден",
                        productCode: "1376552",
                        productImage: dailyShampoo1L
                    },
                    {
                        id: 2,
                        productNameEn: "Hydration Shampoo",
                        productNameMk: "Шампон за хидратација 275мл",
                        productDescription: "Се препорачува шампон за сува коса. Ги чисти влакната на косата, оставајќи ја косата погуста, помека и со полно тело.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со најсоодветниот третман.`,
                        productPrice: "650ден",
                        productCode: "1376550",
                        productImage: hydrationShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "Hydration Conditioner",
                        productNameMk: "Кондиционер за хидратација 1000мл",
                        productDescription: "Хидратантен регенератор за сува коса. Хидрира во длабочина, оставајќи ја косата мека, светла и со полно тело.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја фаворизирате неговата дистрибуција. Почекајте 2-3 минути или до 5 минути за подлабока хидратација. Исплакнете темелно пред стилизирање.`,
                        productPrice: "1450ден",
                        productCode: "1376553",
                        productImage: dailyConditioner1000
                    },
                    {
                        id: 4,
                        productNameEn: "Hydration Conditioner",
                        productNameMk: "Кондиционер за хидратација 250мл",
                        productDescription: "Хидратантен регенератор за сува коса. Хидрира во длабочина, оставајќи ја косата мека, светла и со полно тело.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја фаворизирате неговата дистрибуција. Почекајте 2-3 минути или до 5 минути за подлабока хидратација. Исплакнете темелно пред стилизирање.`,
                        productPrice: "650ден",
                        productCode: "1376551",
                        productImage: dailyConditioner250
                    },
                    {
                        id: 5,
                        productNameEn: "Daily Leave In",
                        productNameMk: "Спреј 275 мл",
                        productDescription: "Спреј без плакнење за моментален хидратантен ефект. Не ја прави косата тешка, ја отплеткува оставајќи извонредна мека, свиленкаста и сјајна коса",
                        productUse: `Добро протресете пред употреба и испарувајте рамномерно на влажна или сува коса. Исчешлајте за да ја фаворизирате неговата дистрибуција и продолжете со стајлирањето без плакнење.`,
                        productPrice: "650ден",
                        productCode: "1383521",
                        productImage: dailyLeaveIn
                    },
                ]
            },
            {
                id: 4,
                name: "Curly Hair",
                nameEn: "curly hair",
                image: curlyHairBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Curly Lover Shampoo",
                        productNameMk: "Шампон за виткана коса 275мл",
                        productDescription: "Шампонот за виткање нежно ги чисти и ги дефинира сите типови на виткање, елиминирајќи го разлетувањето. Обезбедува екстремна хидратација и сјај, оставајќи ја косата мека и лесна без да ја тежи. Збогатен со ленено семе и семе од чиа, веднаш дава ефект на сјај и ја регенерира кадравата, разбушавена, сува, оштетена и без сјај коса.",
                        productUse: `Нанесете на влажен скалп. Масирајте, уверувајќи се дека производот ја покрива целата коса и оставете да делува неколку минути. Исплакнете темелно и повторете ако е потребно. Препорачуваме да го користите производот во комбинација со регенератор за завиткување и еко-пена со виткање.`,
                        productPrice: "600ден",
                        productCode: "1314001",
                        productImage: curlyLoverShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Curly Lover Conditioner",
                        productNameMk: "Кондиционер за виткана коса 275мл",
                        productDescription: "Регенераторот интензивно ја храни и ги дефинира и скротува сите видови на виткање, без да ја тежи косата. Нежно ја отплеткува косата, оставајќи ѝ чувство на мека, лесна, еластична и полна со волумен. Збогатен со ленено семе и чиа семе, ја хидрира и ја остава косата мека и сјајна.",
                        productUse: `Откако ќе ја измиете косата со шампон за виткање, нанесете на влажна коса и исчешлајте ја за да ја подобрите дистрибуцијата. Оставете да делува 3-5 минути, а потоа исплакнете темелно и стилизирајте. Препорачуваме да го завршите третманот со Curllover Eco-Mousse.`,
                        productPrice: "600ден",
                        productCode: "1314011",
                        productImage: curlyLoverConditioner
                    },
                ]
            },
            {
                id: 5,
                name: "Color Block",
                nameEn: "color block",
                image: colorBlockBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Color Block Shampoo",
                        productNameMk: "Колор блок шампон 1000мл",
                        productDescription: "Ги чисти и хидрира влакната на косата. Се препорачува да се стабилизира козметичката боја и да се спречи испирање на бојата, одржувајќи ја живоста на бојата со текот на времето. Ја прави косата поподдржлива, витална и посјајна.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со мелем за стабилизација на боја или маска за интензивна стабилизација на боја за подлабок третман.`,
                        productPrice: "1300ден",
                        productCode: "1383200",
                        productImage: colorBlockShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Color Block Conditioner",
                        productNameMk: "Колор блок кондиционер 1000 мл",
                        productDescription: "Повторно ги балансира, условите и ги заштитува пигментите во боја во внатрешноста на влакната на косата, оставајќи ја косата посјајна, витална и целосно заштитена.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте. Почекајте 2-3 минути, а потоа исплакнете темелно и продолжете со стилизирање.`,
                        productPrice: "1300ден",
                        productCode: "1383210",
                        productImage: colorBlockShampoo
                    }
                ]
            },
            {
                id: 6,
                name: "Blond Hair No Yellow",
                nameEn: "blond hair no yellow",
                image: blondHairNoYellowBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "No yellow shampoo",
                        productNameMk: "Шампон против жолтило 1000 мл",
                        productDescription: "Ги неутрализира несаканите жолто-портокалови тонови и ги возвишува ладните светлини. И дава тело на косата, ги штити влакната на косата и ја подобрува силата, еластичноста и сјајот. Нежно ја чисти и ја зајакнува дури и најтешко оштетената коса, осветлувајќи ја и подобрувајќи ја текстурата на русата коса.",
                        productUse: `Нанесете на влажен скалп. Масирајте го производот во косата и оставете да делува неколку минути. Исплакнете темелно и повторете ако е потребно. За најдобри резултати, препорачуваме користење во комбинација со NOYELLOW регенератор и NOYELLOW спреј.`,
                        productPrice: "1200ден",
                        productCode: "1311000",
                        productImage: noYellowShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "No yellow shampoo",
                        productNameMk: "Шампон против жолтило 275 мл",
                        productDescription: "Ги неутрализира несаканите жолто-портокалови тонови и ги возвишува ладните светлини. И дава тело на косата, ги штити влакната на косата и ја подобрува силата, еластичноста и сјајот. Нежно ја чисти и ја зајакнува дури и најтешко оштетената коса, осветлувајќи ја и подобрувајќи ја текстурата на русата коса.",
                        productUse: `Нанесете на влажен скалп. Масирајте го производот во косата и оставете да делува неколку минути. Исплакнете темелно и повторете ако е потребно. За најдобри резултати, препорачуваме користење во комбинација со NOYELLOW регенератор и NOYELLOW спреј.`,
                        productPrice: "550ден",
                        productCode: "1311001",
                        productImage: noYellowShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "No yellow conditioner",
                        productNameMk: "Кондиционер против жолтило 1000 мл",
                        productDescription: "Ги неутрализира несаканите жолто-портокалови тонови и ги возвишува ладните светлини. И дава тело на косата, ги штити влакната на косата и ја подобрува силата, еластичноста и сјајот. Нежно ја чисти и ја зајакнува дури и најтешко оштетената коса, осветлувајќи ја и подобрувајќи ја текстурата на русата коса.",
                        productUse: `Откако ќе ја измиете косата со шампонот NOYELLOW, нанесете на влажна коса и исчешлајте за рамномерно да се распореди. Оставете да делува 3-5 минути, а потоа исплакнете темелно и стилизирајте. За најдобри резултати, препорачуваме да го прекинете третманот со Спреј NOYELLOW.`,
                        productPrice: "1200ден",
                        productCode: "1311010",
                        productImage: noYellowConditioner
                    },
                ]
            },
            {
                id: 7,
                name: "On Care Scalp Defense",
                nameEn: "on care scalp defense",
                image: onCareScalpDefenseBg,
                products: [
                    // {
                    //     id: 1,
                    //     productNameEn: "Dandruff Control Shampoo",
                    //     productNameMk: "Шампон против првут 250 мл",
                    //     productDescription: "Третман за чистење и прочистување на првут. Благодарение на антимикробното, антимикотичното и антисептичното дејство на Piroctone Olamine и маслото од Копаиба, ефикасно и деликатно чисти, победувајќи го првутот и спречувајќи формирање на нови снегулки. Остава косата да се чувствува лесна и сјајна додека ги смирува чувствителните скалпи.",
                    //     productUse: `Нанесете на влажен скалп. Измасирајте го скалпот и почекајте 3-4 минути пред да ја исплакнете. Повторете ако е потребно. Продолжете со ЛОСИОН ЗА КОНТРОЛА НА ПРВУТ. За најдобри резултати, препорачуваме да го користите секогаш кога повторно ќе се појави првут.`,
                    //     productPrice: "350ден",
                    //     productCode: "1376563",
                    //     productImage: dandruffControlShampoo
                    // }
                    {
                        id: 1,
                        productNameEn: "Dandruff Control Lotion",
                        productNameMk: "Ампули против првут 8 х 8 мл",
                        productDescription: "Благодарение на антимикробното, антимикотичното и разладувачкото дејство на активните принципи, има својства против првут, регулирање на себум и ребалансирање на скалпот, ублажување на чешањето и завршување на благотворното дејство на шампонот за контрола на првут ONcare.",
                        productUse: `Добро протресете пред употреба. По миење и сушење со пешкир на косата со ONcare шампон за контрола на првут, дозирајте ја вистинската количина над скалпот. Масажа во корист распределба. Стилизирајте. За најдобри резултати, препорачуваме да го користите секој пат кога ќе се појави аномалија.`,
                        productPrice: "1200ден",
                        productCode: "1376567",
                        productImage: dandruffControlLotion
                    },
                    {
                        id: 2,
                        productNameEn: "Reduce Shampoo",
                        productNameMk: "Шампон против мастење и себореа 250 мл",
                        productDescription: "Стабилизирачки третман за мрсен скалп. Благодарение на смирувачките, себумрегулирачките и антисептичките ефекти на Tiolisina® Complex 30 и маслото Copaiba, нежно чисти, го отстранува вишокот на себум, враќајќи го скалпот во неговата физиолошка рамнотежа. Ја остава косата лесна и сјајна.",
                        productUse: `Нанесете на влажен скалп. Масирајте и почекајте неколку минути, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со соодветен третман. Користете го со други шампони ONcare кои најдобро одговараат за специфични барања.`,
                        productPrice: "550ден",
                        productCode: "1376564",
                        productImage: reduceShampoo
                    }
                ]
            },
            {
                id: 8,
                name: "On Care Loss Defense",
                nameEn: "on care loss defense",
                image: onCareLossDefenseBg,
                products: [
                    // {
                    //     id: 1,
                    //     productNameEn: "Stimulate Shampoo",
                    //     productNameMk: "Шампон против опаѓање 250 мл",
                    //     productDescription: "Специфичен третман за миење за да се спречи паѓање. Благодарение на интензивното дејство на Redensyl® и скапоцените активни состојки содржани во течностите ONcare, тој специфично чисти со ребалансирање на физиолошките процеси, спречување на губење на косата и зајакнување на структурата на ослабената коса.",
                    //     productUse: `Дистрибуирајте на навлажнета кожа на главата. Добро измасирајте ја кожата и оставете ја неколку минути, а потоа внимателно исплакнете. Повторете ако е потребно. Продолжете со третман за спречување на паѓање Стимулирајте. Се препорачува да се користи редовно за најдобри резултати.`,
                    //     productPrice: "350ден",
                    //     productCode: "1376510",
                    //     productImage: stimulateShampoo
                    // },
                    {
                        id: 1,
                        productNameEn: "Stimulate Lotion",
                        productNameMk: "Ампули против опаѓање 12 х 6 мл",
                        productDescription: "Адјувантен интензивен лосион за стимулирање на опаѓање на косата. Благодарение на интензивното дејство на Redensyl® и скапоцените активни состојки содржани во ONcare flows, ги балансира физиолошките процеси, го стимулира клеточниот метаболизам и ја подобрува микроциркулацијата, придонесувајќи за зголемување на % на косата во анагена фаза. Се бори против атрофија и стареење, зајакнување на ослабените капиларни структури.",
                        productUse: `Рамномерно распоредете ја содржината од половина ампула на чист скалп (не мора претходно измиен) и масирајте на специфичен начин. Без плакнење, продолжете со стајлинг.`,
                        productPrice: "1200ден",
                        productCode: "1376516",
                        productImage: stimulateLotion
                    }
                ]
            },
            {
                id: 9,
                name: "On Care Refill",
                nameEn: "on care refill",
                image: onCareRefillBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Densi Fill Shampoo",
                        productNameMk: "Шампон за волумен и полнење 1000 мл",
                        productDescription: "Идеален за тенка или проретчена коса која треба повторно да се наполни. Ги зајакнува, еластичноста и густината на влакната на косата, оставајќи ја здрава, со полно тело и лесна за чешлање. Не тежи.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со третманот Refill.`,
                        productPrice: "1450ден",
                        productCode: "1383100",
                        productImage: densiFillShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Densi Fill Shampoo",
                        productNameMk: "Шампон за волумен и полнење 275 мл",
                        productDescription: "Идеален за тенка или проретчена коса која треба повторно да се наполни. Ги зајакнува, еластичноста и густината на влакната на косата, оставајќи ја здрава, со полно тело и лесна за чешлање. Не тежи.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со третманот Refill.`,
                        productPrice: "650ден",
                        productCode: "1376512",
                        productImage: densiFillShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "Densi Fill Treatment",
                        productNameMk: "Ампули за полнење 5+5 х 15 мл",
                        productDescription: "Професионален третман со инстант ефект, само за салонска употреба. Од првото нанесување ја реконструира и згуснува фината коса, полнејќи ја одвнатре. Неговата формула со двојна компонента станува активна кога се меша заедно, создавајќи кремаста емулзија која за само неколку минути прави косата да изгледа пополна и погуста. Не тежи.",
                        productUse: `Измешајте Refill 1 со Refill 2 во сад додека не добиете кремаста емулзија. Почекајте неколку минути за да овозможите оптимално активирање на активните принципи. Со четка нанесете на исчистена и исушена коса со крпа. Дистрибуирајте го производот со широка четка, масирајте и почекајте 5 минути. Исплакнете темелно и продолжете со стилизирање.`,
                        productPrice: "1250ден",
                        productCode: "1376518",
                        productImage: densiFillTreatment
                    },
                    {
                        id: 4,
                        productNameEn: "Densi Fill Fast Foam",
                        productNameMk: "Брз спреј за волумен и обнова 200 мл",
                        productDescription: "Третман со спреј за волумен, идеален за фина или ретка коса на која им е потребен ефект на полнење. Веднаш ги зајакнува, еластичноста и густината на влакната на косата, оставајќи ја здрава, со полно тело и лесна за чешлање.",
                        productUse: `Протресете пред употреба. Испрскајте рамномерно на исчистена и влажна коса, па исчешлајте. Почекајте само 30 секунди. Исплакнете темелно со топла вода, а потоа продолжете со стилизирање.`,
                        productPrice: "650ден",
                        productCode: "1376728",
                        productImage: densiFillFastFoam
                    },
                ]
            },
            {
                id: 10,
                name: "Smooth",
                nameEn: "smooth",
                image: onCareSmoothBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Smooth Milk",
                        productNameMk: "Млеко за измазнување 275 мл",
                        productDescription: "Ја измазнува кутикулата и го елиминира ефектот на разлетување, припитомувајќи ја, негувајќи и заштитувајќи ја косата. Остава косата да изгледа здрава и полна со тело.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја подобрите дистрибуцијата и, без плакнење, продолжете со стилизирање. Препорачливо е да користите мала количина и на сува коса, пред да користите пегла.`,
                        productPrice: "850ден",
                        productCode: "1383341",
                        productImage: smoothMilk
                    },
                    {
                        id: 2,
                        productNameEn: "Smooth Shampoo",
                        productNameMk: "Шампон за измазнување 1000 мл",
                        productDescription: "Шампон особено погоден за долга и незаплеткана коса. Нежно ја чисти и помага при скротување на косата. Го контролира ефектот на разлетување и остава сјајна и податлива коса.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со најсоодветниот третман.`,
                        productPrice: "1450ден",
                        productCode: "1383300",
                        productImage: smoothShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "Smooth Conditioner",
                        productNameMk: "Кондиционер за измазнување 1000 мл",
                        productDescription: "Кондиционер особено погоден за долга и незаплеткана коса. Го контролира ефектот на разлетување и остава сјајна и податлива коса.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја фаворизирате неговата дистрибуција. Почекајте 2-3 минути или до 5 минути за подлабока хидратација. Исплакнете темелно и продолжете со стилизирање.`,
                        productPrice: "1450ден",
                        productCode: "1383310",
                        productImage: smoothShampoo
                    },
                ]
            },
            {
                id: 11,
                name: "On Care Repair",
                nameEn: "on care repair",
                image: onCareRepairBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Repair Shampoo",
                        productNameMk: "Шампон за инстант регенерација 1000 мл",
                        productDescription: "Шампон особено погоден за осетлива, оштетена, кршлива коса. Нежно ја чисти и оживува косата, оставајќи ја негувана и силна.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со најсоодветниот третман.`,
                        productPrice: "1450ден",
                        productCode: "1383400",
                        productImage: repairShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Repair Shampoo",
                        productNameMk: "Шампон за инстант регенерација 275 мл",
                        productDescription: "Шампон особено погоден за осетлива, оштетена, кршлива коса. Нежно ја чисти и оживува косата, оставајќи ја негувана и силна.",
                        productUse: `Нанесете на влажен скалп. Нежно масирајте го, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со најсоодветниот третман.`,
                        productPrice: "650ден",
                        productCode: "1383401",
                        productImage: repairShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "Repair Balm",
                        productNameMk: "Кондиционер за инстант регенерација 1000 мл",
                        productDescription: "Кондиционер особено погоден за чувствителна, оштетена, кршлива коса. Ја отплеткува и кондиционира косата, оставајќи ја силна и сјајна.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја фаворизирате неговата дистрибуција. Почекајте 2-3 минути или до 5 минути за подлабока хидратација. Исплакнете темелно и продолжете со стилизирање.`,
                        productPrice: "1450ден",
                        productCode: "1383410",
                        productImage: repairShampoo
                    },
                    {
                        id: 4,
                        productNameEn: "Repair Balm",
                        productNameMk: "Кондиционер за инстант регенерација 275 мл",
                        productDescription: "Кондиционер особено погоден за чувствителна, оштетена, кршлива коса. Ја отплеткува и кондиционира косата, оставајќи ја силна и сјајна.",
                        productUse: `Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да ја фаворизирате неговата дистрибуција. Почекајте 2-3 минути или до 5 минути за подлабока хидратација. Исплакнете темелно и продолжете со стилизирање.`,
                        productPrice: "700ден",
                        productCode: "1383411",
                        productImage: repairShampoo
                    },
                ]
            },
            {
                id: 12,
                name: "Rebuilding",
                nameEn: "Rebuilding",
                image: rebuildingBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Chelating Shampoo",
                        productNameMk: "Хелатен шампон 1000 мл",
                        productDescription: "Прочистувачки хелатен шампон збогатен со хијалуронска киселина. Прочистува длабински и ги отстранува нечистотиите од длабочина на косата, подготвувајќи ја косата за третман за обновување.",
                        productUse: `Нанесете на влажен скалп. Потоа масирајте во косата, пред темелно да ја исплакнете. Повторете ако е потребно.`,
                        productUseList: [
                            {
                                id: 1,
                                item: chelatingProductUse
                            }
                        ],
                        productPrice: "1450ден",
                        productCode: "1313000",
                        productImage: chelatingShampoo,
                        productOnlyBulk: true,
                    },
                    {
                        id: 2,
                        productNameEn: "Rebuild Treatment",
                        productNameMk: "Третман за обновување 1000 мл",
                        productDescription: "Третман за обнова збогатен со хијалуронска киселина и амино киселини. Ја обновува косата и создава заштитна фолија која ја одржува правилната хидратација на кутикулата.",
                        productUse: `Откако ќе го употребите Хелатен шампон, нанесете на должините и врвовите, а потоа исчешлајте за да ја подобрите рамномерната распределба. Оставете да делува 10-15 минути, а потоа исплакнете. 
                        ВРЕМЕ НА ЧЕКАЊЕ: 10-15 минути`,
                        productUseList: [
                            {
                                id: 1,
                                item: chelatingProductUse
                            }
                        ],
                        productPrice: "1450ден",
                        productCode: "1313010",
                        productImage: rebuildTreatment,
                        productOnlyBulk: true,
                    },
                    {
                        id: 3,
                        productNameEn: "Chelating Spray",
                        productNameMk: "Хелатен спреј 250 мл",
                        productDescription: "Третман кој ја штити косата пред хемиски услуги. Ефикасно ги отстранува нечистотиите и најчестите акумулации на метали и минерали. Ја заштитува косата од штетното дејство на тврда вода, ја враќа природната состојба на косата пред било која техничка услуга, одржувајќи го правилното ниво на хидратација и подготвувајќи ги влакната за следните третмани.",
                        productUse: `Испрскајте на сува коса од коренот до врвовите. Нанесете 8 до 20 прскања фокусирајќи се на должините. Оставете да делува 3-5 минути. Не плакнете, продолжете директно со технички сервис.`,
                        productUseList: [
                            {
                                id: 1,
                                item: chelatingProductUse
                            }
                        ],
                        productPrice: "700ден",
                        productCode: "1313020",
                        productImage: chelatingSpray,
                        productOnlyBulk: true,
                    },
                ]
            },
            {
                id: 13,
                name: "Artistic Flair",
                nameEn: "artistic flair",
                image: artisticFlairBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Pearl Shampoo",
                        productNameMk: "Бисерен шампон 10 л",
                        productDescription: "Лесниот Бисерен шампон е погоден за сите типови коса - идеален за честа употреба. Исто така, тој е мултифункционален витамин со навлажнувачки и негувачки ефекти.",
                        productUse: `Нанесете на влажна коса и масирајте, оставете да делува 1-2 минути, а потоа исплакнете темелно. Повторете ја апликацијата доколку е потребно.`,
                        productPrice: "2300ден",
                        productCode: "1370733",
                        productImage: pearlShampoo
                    },
                    {
                        id: 2,
                        productNameEn: "Green Apple Shampoo",
                        productNameMk: "Шампон од зелено јаболко 1000 мл",
                        productDescription: "Средство за чистење погодно за сите типови коса. Ефикасно и деликатно, идеално за често миење, особено за коса која има тежина. Ја зајакнува структурата на косата давајќи мекост и сјај.",
                        productUse: `Нанесете мала количина на влажна коса. Масирајте го. Почекајте неколку минути. Исплакнете и повторете ако е потребно. Продолжете со следниот третман.`,
                        productPrice: "700ден",
                        productCode: "1370751",
                        productImage: greenAppleShampoo
                    },
                    {
                        id: 3,
                        productNameEn: "Midollo Di Bamboo Shampoo",
                        productNameMk: "Шампон од екстракт од бамбус 1000 мл",
                        productDescription: "Чистење за слаба и кршлива коса. Неговата формула го чисти и реструктуира стеблото давајќи здрав и сјаен изглед. Неутрален, нежен третман, идеален за многу сува или кршлива коса. Ја остава косата со полно тело и обемна.",
                        productUse: `Нанесете мала количина на влажна коса. Масирајте го и почекајте неколку минути. Исплакнете и повторете ако е потребно. Продолжете со следниот третман.`,
                        productPrice: "700ден",
                        productCode: "1370754",
                        productImage: midolloDiBambooShampoo
                    },
                    {
                        id: 4,
                        productNameEn: "Keratin Shampoo",
                        productNameMk: "Шампон за регенерација 1000мл",
                        productDescription: "Кератин за чистење специфичен за сува и оштетена коса. Стресот и прекумерната употреба на топли алатки може да ја уништат косата. Дејството на кератин ја регенерира и оживува посувата коса, правејќи ја мека, силна и витална.",
                        productUse: `Нанесете мала количина на влажна коса. Масирајте и почекајте неколку минути. Исплакнете и повторете ако е потребно. Продолжете со следниот третман.`,
                        productPrice: "700ден",
                        productCode: "1370750",
                        productImage: keratinShampoo
                    },
                    {
                        id: 5,
                        productNameEn: "Keratin Conditioner",
                        productNameMk: "Кондиционер за регенерација 1000мл",
                        productDescription: "Третман на коса со кератин. Благодарение на својата формула, ја враќа свиленоста и сјајот на досадната и нагласена коса. И дава интензивна хидратација на косата, ја обновува и поправа косата давајќи им сила дури и на оние кои се најстресени.",
                        productUse: `Нанесете мала количина на чиста, влажна коса и исчешлајте ја. Почекајте неколку минути и исплакнете темелно. Стилизирајте по желба.`,
                        productPrice: "700ден",
                        productCode: "1370755",
                        productImage: keratinConditioner
                    },
                    {
                        id: 6,
                        productNameEn: "Hair Conditioner",
                        productNameMk: "Кондиционер за сува коса 1000 мл",
                        productDescription: "Регенератор за коса кој благодарение на формулата богата со протеини ја храни и заштитува косата, враќајќи ја вистинската хидратација. И дава сјај на досадна и сува коса, идеална за честа употреба, бидејќи не тежи.",
                        productUse: `Нанесете мала количина на чиста, влажна коса и исчешлајте ја. Почекајте неколку минути и исплакнете темелно. Стилизирајте по желба.`,
                        productPrice: "700ден",
                        productCode: "1370756",
                        productImage: hairConditioner
                    },
                    {
                        id: 7,
                        productNameEn: "Keratin Mask",
                        productNameMk: "Кератинска маска 1000 мл",
                        productDescription: "Кератинска маска за реструктуирање со висок капацитет за кондиционирање и поправка. Благодарение на својата специјална формула, ги реструктуира сериозно оштетените капиларни структури во нив, за поздрава и витална коса.",
                        productUse: `Распоредете на влажна и чиста коса, исчешлајте, оставете да делува 5-10 минути, исплакнете темелно со млака вода. Продолжете со стајлинг.
                        Препорачуваме: за да се зголеми ефикасноста, користете под топлина.`,
                        productPrice: "900ден",
                        productCode: "1370700",
                        productImage: keratinMask
                    },
                     {
                        id: 8,
                        productNameEn: "Duo Phasette",
                        productNameMk: "Двофазен кондиционер 450 мл",
                        productDescription: "Спреј за регенератор без плакнење. Дава отплеткување и сјај, го елиминира електростатскиот ефект така што го олеснува чешлањето и за време на шишањето и стилизирањето. Ја враќа физиолошката pH вредност на третираната структура",
                        productUse: `Добро протресете го пред употреба. Испрскајте во мали дози на чиста, влажна коса и исчешлајте за темелно да се распореди. Не плакнете. Стилизирајте по желба.`,
                        productPrice: "750ден",
                        productCode: "1370716",
                        productImage: duoPhasette
                    }
                ]
            },
            {
                id: 14,
                name: "Авокадо ампули",
                nameEn: "avocado shots",
                image: avocadoShotsBg,
                products: [
                     {
                        id: 1,
                        productNameEn: "Avocado Shot",
                        productNameMk: "Ампули од авокадо 12 х 10 мл",
                        productDescription: "Награден во козметика како иновативен производ. Хидратантен и заштитен серум за суперхрана без плакнење. Авокадо Шот спречува сувост и дехидрација на влакната на косата, заштитувајќи ги и хидрирајќи ги во длабочина. Идеален за секојдневна употреба на природна или обоена, сува и оштетена коса. Неговата лесна и течна текстура веднаш продира во длабочина на косата, подобрувајќи го нејзиниот изглед, оставајќи ја мека и заштитена од ден на ден.",
                        productUse: `Протресете пред употреба. Нанесете на чиста, исушена со пешкир коса. Исчешлајте за да се распореди рамномерно, а потоа стилизирајте без плакнење, стилизирајте. Во комбинација со маски „All in one“, додава дополнителна благосостојба на косата.`,
                        productPrice: "1800ден",
                        productCode: "1370596",
                        productImage: avocadoShot
                    },
                ]
            },
            {
                id: 15,
                name: "All In One",
                nameEn: "all in one",
                image: allInOneBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "All In One Spray",
                        productNameMk: "Мултифункционален третман 150 мл",
                        productDescription: "All In One е хранлива маска со спреј која содржи 15 различни предности во еден производ: ја поправа оштетената коса: спречува расцепени врвови, ја храни косата, ја навлажнува сува коса, ја штити косата од УВ зраците, го подобрува волуменот на косата. , ја кондиционира косата без да ја тежи, ја отплеткува косата и го олеснува чешлањето, дава свиленкаст и мекост, ја измазнува кутикулата, го елиминира разлетувањето, ја штити косата од топлина, ја олеснува употребата на пегла и фен, го задржува комплетот подолго и ја остава косата сјајна и здрава.",
                        productUse: `НА ВЛАЖНА КОСА
                        Откако ќе ја исчистите косата, испрскајте ја на коса исушена со пешкир од околу. 20 см, и исчешлајте за темелно да го распоредите. Не го плакнете. Продолжете со стајлингот.
                        НА СУВА КОСА
                        Користете го производот за навлажнување, освежување на комплетот и заштита на косата. Испрскајте ја на дланките и распоредете ја по косата, а потоа ресетирајте ја косата по желба.`,
                        productPrice: "850ден",
                        productCode: "13704996",
                        productImage: allInOneSpray
                    },
                    {
                        id: 2,
                        productNameEn: "All In One Color Spray",
                        productNameMk: "Мултифункционален колор третман 150 мл",
                        productDescription: "All In One е маска со спреј за обоена и изветвена коса. Благосостојба и заштита во 15 придобивки: ја заштитува козметичката боја, ги одржува сјајните и интензивните светлини, го подобрува времетраењето на бојата, длабински ја храни обоената и истакната коса, деликатно ја навлажнува третираната и оштетена коса, ја штити косата од оштетување на околината, ја обновува косата, ја одржува природната еластичност на косата, ја кондиционира косата без да ја тежи, ја прави косата полесно да се расплетува, ја остава косата мека и свиленкаста, го елиминира разлетувањето, ја штити косата од топлината на фен и пегла, го продолжува времетраењето на обликувањето, ја остава косата сјајна и полна со тело.",
                        productUse: `НА ВЛАЖНА КОСА
                        Откако ќе ја исчистите косата, испрскајте ја на коса исушена со пешкир од околу. 20 см, и исчешлајте за темелно да го распоредите. Не го плакнете. Продолжете со стајлингот.
                        НА СУВА КОСА
                        Користете го производот за навлажнување, освежување на комплетот и заштита на косата. Испрскајте ја на дланките и распоредете ја по косата, а потоа ресетирајте ја косата по желба.`,
                        productPrice: "850ден",
                        productCode: "1370598/5",
                        productImage: allInOneColorSpray
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        category: `Styling`,
        lineupTitle: 'СТИЛИЗИРАЊЕ',
        lineupDescription: 'професионални производи за дефинирање на секаков тип на стил',
        lineupImage: stylingLineupImage,
        imageTop: stylingTopImage,
        subCategory: [
            {
                id: 1,
                name: "Стилизирање",
                nameEn: "styling",
                image: stylingBg,
                products: [
                    {
                        id: 1,
                        productNameEn: "Pump Too",
                        productNameMk: "Спреј за волумен 200 мл",
                        productDescription: "Спреј специјално дизајниран да создаде волумен на корените. Дава облик и поддршка на косата со невидливо и долготрајно држење. Специфично за фина коса со недостаток на тело.",
                        productUse: `Испрскајте на чиста, исушена со пешкир коса неколку сантиметри од основата на секој прамен. Стилизирајте по желба.`,
                        productPrice: "550ден",
                        productCode: "1382509",
                        productImage: pumpToo
                    },
                    {
                        id: 2,
                        productNameEn: "Design Curl",
                        productNameMk: "Флуид за локни 250 мл",
                        productDescription: "Течност за стилизирање која е идеална за совршено дизајнирање и дефинирање на кадрици, одржувајќи природен еластичен ефект. Дефинира, го отстранува ефектот на разлетување и дава сјај. Идеален за употреба со фен, пегла, витлери или дифузер.",
                        productUse: `Истурете соодветна количина на производ во раце во зависност од видот и должината на косата. Распоредете рамномерно на чиста, исушена коса со крпа, а потоа стилизирајте по желба. За да додадете дефиниција, аплицирајте повторно по стилизирањето.`,
                        productPrice: "600ден",
                        productCode: "1382500",
                        productImage: designCurl
                    },
                    {
                        id: 3,
                        productNameEn: "Matt Molder",
                        productNameMk: "Мат паста 100 мл",
                        productDescription: "На стилизираната коса и дава мат изглед и ја одржува косата навлажнета. Неговата избалансирана мешавина на восочни компоненти му дава на стајлингот природна, средна јачина задржување. Не ја отежнува косата и не остава остатоци.",
                        productUse: `Масирајте помеѓу рацете и работете на сува или влажна коса, во зависност од посакуваниот изглед.`,
                        productPrice: "600ден",
                        productCode: "1378303",
                        productImage: mattMolder
                    },
                    {
                        id: 4,
                        productNameEn: "Light On",
                        productNameMk: "Силиконски капки 100 мл",
                        productDescription: "Го елиминира електростатскиот ефект, давајќи и на косата максимален сјај и свиленкаст. Ја скротува косата, ги запечатува разделените врвови и обезбедува силен екран против влажност. Завршен допир за здрава, со полно тело и сјајна коса за миг.",
                        productUse: `Втријте мала количина од производот во дланките на вашите раце, а потоа нанесете на сува коса.`,
                        productPrice: "600ден",
                        productCode: "1382517",
                        productImage: lightOn
                    },
                    {
                        id: 5,
                        productNameEn: "Powder Vol",
                        productNameMk: "Пудра за волумен 5г",
                        productDescription: "Идеален за давање поддршка и волумен на корените и по должината. Дава природен мат-ефект без сушење на косата или оставање остатоци.",
                        productUse: `Протресете пред употреба. Посипете го производот директно на сува коса од коренот и премачкајте ја косата со прстите.`,
                        productPrice: "600ден",
                        productCode: "1382518",
                        productImage: powderVol
                    },
                    {
                        id: 6,
                        productNameEn: "Fast Create",
                        productNameMk: "Восок во спреј 200 мл",
                        productDescription: "Може да се користи како лак за коса. Идеален за давање текстура, дефиниција и поддршка без оптоварување на косата. Брза и практична алтернатива на традиционалниот восок.",
                        productUse: `Добро протресете. Нанесете на сува коса на растојание од 15-20 см. Може да се користи или по целата коса или само на одредени точки, за подобра дефиниција.`,
                        productPrice: "600ден",
                        productCode: "1382502",
                        productImage: fastCreate
                    },
                    {
                        id: 7,
                        productNameEn: "Silky Way",
                        productNameMk: "Крем за измазнување 200 мл",
                        productDescription: "Идеален за добивање на мазен и сјаен стил отпорен на влажност. Го елиминира разлетувањето, оставајќи ја косата кондиционирана и сјајна.",
                        productUse: `Истурете во вашите раце и распоредете на чиста и исушена со крпа коса. Исчешлајте за подобро распоредување и стилизирање без плакнење.`,
                        productPrice: "600ден",
                        productCode: "1382512",
                        productImage: silkyWay
                    },
                    {
                        id: 8,
                        productNameEn: "Up To 230",
                        productNameMk: "Спреј за термичка заштита 230 мл",
                        productDescription: "Заштитен и кондиционен спреј со одлични антистатички својства и својства за формирање филм кој нуди целосна заштита од топлина. Ја заштитува косата од топлината на фен, фигаро и пегла. Ја остава косата хидрирана и сјајна. Го олеснува сушењето со фен и стилизирањето",
                        productUse: `Рамномерно прскајте по должина и врвови на растојание од 20 см. Користете на влажна или сува коса пред да го користите фен или други топли алатки за стилизирање.`,
                        productPrice: "650ден",
                        productCode: "1382511",
                        productImage: upto230
                    },
                    {
                        id: 9,
                        productNameEn: "Shape Strong",
                        productNameMk: "Пена за коса 400 мл",
                        productDescription: `Мус за стилизирање. Обезбедува сјај и волумен на косата со силно држење, лесно за чешлање. Обликувајте ги локните правејќи ги еластични и дефинирани. Иновативна формула со максимална заштита од топлината на фен при сушење.
                        Остава цветен мирис.`,
                        productUse: `Добро протресете го пред употреба и држете го наопаку. Нанесете го производот на исчистена коса исушена со пешкир. Не плакнете. Се суши со фен без четкање.`,
                        productPrice: "550ден",
                        productCode: "1370712",
                        productImage: shapeStrong
                    },
                    {
                        id: 10,
                        productNameEn: "Excel Strong",
                        productNameMk: "Лак за коса 500 мл",
                        productDescription: `Силен лак за коса со еколошки погон. Идеален за сите фризури за фиксирање и стилизирање на прамени. Брзо се суши без оптоварување и без остаток, се отстранува со чешлање со четка.
                        Цветен мирис.`,
                        productUse: `Прскајте на растојание од околу 30 см од косата. За да добиете волуменски ефект, испрскајте директно на корените.`,
                        productPrice: "550ден",
                        productCode: "1370710",
                        productImage: excelStrong
                    },
                ]
            }
        ]
    },
]

export default dataConfig;


export const productsLineupList = [
    {
        id: 1,
        productNameEn: "Silky Way",
        productNameMk: "Крем за измазнување 200 мл",
        productDescription: "Идеален за добивање на мазен и сјаен стил отпорен на влажност. Го елиминира разлетувањето, оставајќи ја косата кондиционирана и сјајна.",
        productUse: `Истурете во вашите раце и распоредете на чиста и исушена со крпа коса. Исчешлајте за подобро распоредување и стилизирање без плакнење.`,
        productPrice: "500ден",
        productCode: "1382512",
        productImage: silkyWay,
        productLink: "/category/styling/3/styling/1/silky-way/7",
    },
    {
        id: 2,
        productNameEn: "Densi Fill Fast Foam",
        productNameMk: "Брз спреј за волумен и обнова 200 мл",
        productDescription: "Третман со спреј за волумен, идеален за фина или ретка коса на која им е потребен ефект на полнење. Веднаш ги зајакнува, еластичноста и густината на влакната на косата, оставајќи ја здрава, со полно тело и лесна за чешлање.",
        productUse: `Shake before using. Spray evenly on cleaned and damp hair, then comb. Wait for 30 seconds only. Rinse thoroughly with warm water, and then proceed with styling.`,
        productPrice: "500ден",
        productCode: "1376728",
        productImage: densiFillFastFoam,
        productLink: "/category/treatments/2/on-care-refill/9/densi-fill-fast-foam/4",
    },
    {
        id: 3,
        productNameEn: "Reduce Shampoo",
        productNameMk: "Шампон против мастење и себореа 250 мл",
        productDescription: "Стабилизирачки третман за мрсен скалп. Благодарение на смирувачките, себумрегулирачките и антисептичките ефекти на Tiolisina® Complex 30 и маслото Copaiba, нежно чисти, го отстранува вишокот на себум, враќајќи го скалпот во неговата физиолошка рамнотежа. Ја остава косата лесна и сјајна.",
        productUse: `Нанесете на влажен скалп. Масирајте и почекајте неколку минути, а потоа исплакнете темелно. Повторете ако е потребно. Продолжете со соодветен третман. Користете го со други шампони ONcare кои најдобро одговараат за специфични барања.`,
        productPrice: "550ден",
        productCode: "1376564",
        productImage: reduceShampoo,
        productLink: "/category/treatments/2/on-care-scalp-defense/7/reduce-shampoo/2",
    },
    {
        id: 4,
        productNameEn: "Colorevo Oxy",
        productNameMk: "Хидроген 3% 1000мл",
        productDescription: `Специфично дизајниран да добие впечатливи резултати со сите нијанси на COLOREVO. Синергијата помеѓу производите гарантира: прилепување на смесата, целосна покриеност на седата коса, максимална сјајност и долготрајни бои, совршен развој на дејството на избелување и намален ризик од иритации. Цветен мирис, во склад со мирисот COLOREVO.
        Достапно во 4 варијации: 10, 20, 30, 40 волумени.`,
        productUse: `
        За да постигнете најдобри резултати со COLOREVO, препорачуваме да се повикате на информациите подолу:
VOL. % ФУНКЦИЈА
10 3 За затемнување, за осветлување до 1 тон или тон во тон
20 6 Целосно покривање на седата коса, за потемна боја или за осветлување 1-2 тона
30 9 За покривање на седата коса со осветлување 2-3 тони
40 12 За осветлување 4-5 тона со COLOREVO BLOND, за осветлување 4 тона со COLOREVO GLITCH`,
        productPrice: "400ден",
        productCode: "1385010",
        productImage: colorevoOxy,
        productLink: "/category/technical-products/1/colorevo/1/colorevo-oxy/2",
    },
]