import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import dataConfig from "../../Utils/Data/data-config";
import Navigation from "../../Components/Navigation/Navigation";
import topSectionImage from '../../Assets/Images/product-page-top.jpg';
import ProductCard from "../../Components/Product Card/ProductCard";
import Button from "../../Components/Button/Button";
import './productpagestyle.css';
import Footer from "../../Components/Footer/Footer";
import { CartContext } from "../../Utils/useContext Hooks/CartContextComponent";
import { AddToCartAction } from "../../Utils/App Functions/appFunctions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProductPage = () => {

    const navigate = useNavigate();
    const { categoryname, productid, subcategoryname} = useParams();
    const { cartItems, setCartItems } = useContext(CartContext);
    const [productData, setProductData] = useState();
    const [quantity, setQuantity] = useState(1);
    const [additionalOptions, setAdditionalOptions] = useState(false);
    const [availableForBulkOnly, setAvailableForBulkOnly] = useState(false);

    const additionalOptionsRef = useRef();

    useEffect(() => {
        const formattedCategoryName = categoryname.replaceAll("-", " ");
        const formattedSubCategoryName = subcategoryname.replaceAll("-", " ");
        const fetchCategory = dataConfig.find((item) => item.category.toLowerCase() === formattedCategoryName);
        if(fetchCategory) {
            const fetchSubCategory = fetchCategory.subCategory.find((item) => item.nameEn.toLowerCase() === formattedSubCategoryName);
            if(fetchSubCategory) {
                const fetchProduct = fetchSubCategory.products.find((item) => item.id === parseInt(productid));
                setProductData(fetchProduct);
                fetchProduct.additionalOptions ? setAdditionalOptions(true) : setAdditionalOptions(false);
                fetchProduct.productOnlyBulk ? setAvailableForBulkOnly(true) : setAvailableForBulkOnly(false);
                console.log(fetchProduct);
            }
        }
    }, [categoryname, subcategoryname, productid])

    useEffect(() => {
        if(quantity === 0) { setQuantity(1); }
    }, [quantity])

    if(!productData) { return null; }


    return (
        <>
           <Navigation/>
           <section className="product-page-top-section">
                <img src={topSectionImage} alt="" className="w-100"></img>
           </section>

           <section className="product-page-product-container pt-5 pb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <ProductCard props={productData.productImage}/>
                        </div>

                        <div className="col-md-7">
                            <h2>{productData.productNameMk}</h2>
                            <span className="size-20 fw-bold dark-orange-color d-inline-block pt-3">Цена: {productData.productPrice}</span>
                            <p className="size-20 pt-3 grey-color"><span className="dark-orange-color fw-bold">Опис: </span>{productData.productDescription}</p>
                            <p className="size-20 pt-3 grey-color"><span className="dark-orange-color fw-bold">Шифра: </span>{productData.productCode}</p>
                            <div className="add-quantity-wrapper mt-3">
                                <button className="add-quantity-button" onClick={() => setQuantity(quantity - 1)}>-</button>
                                <span className="add-quantity-button d-inline-block">{quantity}</span>
                                <button className="add-quantity-button" onClick={() => setQuantity(quantity + 1)}>+</button>
                            </div>
                            {
                                additionalOptions ?
                                <div className="mb-3 mt-3">
                                <label htmlFor="inputPhone" className="form-label size-20 dark-orange-color fw-bold">Внесете број на продукт</label>
                                <input type="text" className="form-control w-25" id="inputPhone" ref={additionalOptionsRef}/>
                            </div>
                            : ``
                            }
                            
                            <Button text="Нарачај големо" classes="mt-4 text-uppercase" onClick={() => navigate('/bulk-cart')}/>
                            {
                                availableForBulkOnly ? `` :
                                <Button text="Додади во кошничка" classes="mt-4 mb-3 mx-4 text-uppercase" onClick={() => AddToCartAction(productData, cartItems, setCartItems, quantity, additionalOptionsRef)}/>
                            }
                        </div>
                    </div>
                </div>

                <div className="container product-page-description">
                <div className="row">
                    <h5 className="fw-bold mb-3">Начин на употреба</h5>
                        <p className="grey-color size-18">{productData.productUse}</p>
                        {
                            productData.productUseList ?
                            <>
                            <img src={productData.productUseList[0].item} alt="" className="w-25" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
                            <div class="modal fade modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                    <div class="modal-body">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    <img src={productData.productUseList[0].item} alt="" className="w-100"/>    
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </>
                            : ``
                        }
                    </div>
                </div>
           </section>

           <Footer/>
           <ToastContainer />
        </>
    )
}

export default ProductPage;

