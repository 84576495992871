import { useEffect, useState } from "react";
import { useParams } from "react-router";
import dataConfig from "../../Utils/Data/data-config";
import Navigation from "../../Components/Navigation/Navigation";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";


const SubCategoryPage = () => {

    const { id, categoryname, subcategoryname} = useParams();

    const [categoryName, setCategoryName] = useState();
    const [subCategoryName, setSubCategoryName] = useState();
    const [subCategoryData, setSubCategoryData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const formattedCategoryName = categoryname.replaceAll("-", " ");
            const formattedSubCategoryName = subcategoryname.replaceAll("-", " ");
            setCategoryName(formattedCategoryName);
            setSubCategoryName(formattedSubCategoryName);
            const matchedCategoryData = dataConfig.find((item) =>
              item.category.toLowerCase() === formattedCategoryName
            );
            if (matchedCategoryData) {
              const matchedSubCategoryData = matchedCategoryData.subCategory.find((item) =>
                item.nameEn.toLowerCase() === formattedSubCategoryName
              );
              if (matchedSubCategoryData) { setSubCategoryData(matchedSubCategoryData); } 
            }
          } catch (error) {
            console.log(error);
          }
        };
      
        fetchData();
      }, [categoryname, subcategoryname]);

    return (
        <>
           <Navigation/>

           <section className="single-category-section">
                <div className="container-fluid pt-5 pb-5 bg-white">
                    <div className="row text-center flex-column justify-content-center align-items-center">
                        <div className="col-md-6">
                            <h1 className="single-category-title title-text fw-bold">{subCategoryData.name}</h1>
                        </div>

                        <div className="col-md-6 w-100">
                            <img src={subCategoryData.image} alt="" className="w-50"/>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="row justify-content-center">
                        <h1 className="text-center pb-5">Продукти</h1>
                        {
                        subCategoryData.products&&subCategoryData.products.map((item) => (
                                 <div className="col-md-4 mt-3 mb-3" key={item.id}>
                                     <Link to={`${item.productNameEn.replace(/\s/g, '-').toLowerCase()}/${item.id}`}>
                                    <div className="text-center pb-5 bg-white">
                                        <img src={item.productImage} className="w-100" alt="" style={{height: '416px', objectFit: 'contain'}}/>
                                        <span className="grey-color d-inline-block mt-3">{item.productNameEn}</span>
                                        <h2 style={{fontSize: '1.7rem'}}>{item.productNameMk}</h2>
                                        <span className="size-20 dark-orange-color fw-bold">{item.productPrice}</span>
                                    </div>
                                    </Link>
                                </div>
                        ))
                    }
                    </div>
                </div>
            
            </section>

            <Footer/>
        </>
    )
}

export default SubCategoryPage;