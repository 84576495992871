import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddToCartAction = async (productData, cartItems, setCartItems, quantity, additionalOptionsRef) => {
    try {
        let additionalOptionsValue;
        if(additionalOptionsRef.current === undefined || additionalOptionsRef === null) {
            additionalOptionsValue = ``;
        }
        else {
            additionalOptionsValue = additionalOptionsRef.current.value;
            if(additionalOptionsValue <= 0) {
                errorToast('Изберете соодветен број на продукт');
                return null;
            } 
        }

        const existingItem = cartItems.find(item => item.productCode === productData.productCode);
        if (existingItem !== undefined) {
            const updateCart = cartItems.map((item, index) => {
                if (item.productCode === existingItem.productCode) {
                    return {
                        ...item,
                        quantity: item.quantity + quantity,
                        additionalOptionsValue: additionalOptionsValue
                    };
                }
                return item;
            });

            setCartItems(updateCart);
            localStorage.setItem("cartItems", JSON.stringify(updateCart));
            successToast('Додадено во кошничка');
        } else {
            const updateCart = [...cartItems, { ...productData, quantity, additionalOptionsValue: additionalOptionsValue }];
            setCartItems(updateCart);
            localStorage.setItem("cartItems", JSON.stringify(updateCart));
            successToast('Додадено во кошничка');
        }
    } catch (error) {
        console.log(error);
    }
};


export const deleteProductFromCart = async (product, cartItems, setCartItems) => {
    try {
        const updateCart = await cartItems.filter((item) => item.productCode !== product.productCode);
        setCartItems(updateCart);
    } catch (error) {
        console.log(error);
    }
}

export const AddToBulkCartAction = async (quantityRef, selectRef, selectedProducts, setSelectedProducts, allProducts, setError, additionalOptionsRef) => {
    try {
        const quantityValue = parseInt(quantityRef.current.value);
        const productValue = selectRef.current.value;
        let additionalOptionsValue;

        if(isNaN(quantityValue)) {
            setError("Внесете количина!")
            return null;
        }

        if(quantityValue < 0 || quantityValue > 99999) {
            setError('Внесете соодветна количина!');
            return null;
        }

        if(productValue === "Сите продукти") {
            setError("Изберете правилен продукт!");
            return null;
        }

        if(additionalOptionsRef.current !== undefined && additionalOptionsRef.current !== null) {
            additionalOptionsValue = additionalOptionsRef.current.value;
            if(additionalOptionsValue == `` || additionalOptionsValue < 0) {
                setError("Изберете правилен број на продукт!");
                return null;
            }
        }
        else {
            additionalOptionsValue = ``;
        }

        setError("");

        const existingItem = selectedProducts.find((item) => item.productCode === productValue);

        if(existingItem !== undefined) {
            const updateCart = selectedProducts.map((item) => {
                if (item.productCode === existingItem.productCode) {
                    return {
                        ...item,
                        quantity: item.quantity + quantityValue,
                        additionalOptionsValue: additionalOptionsValue
                    };
                }
                return item;
            });

            setSelectedProducts(updateCart);
            localStorage.setItem("bulkCartItems", JSON.stringify(updateCart));
        }
        else {
            const newProduct = allProducts.find((item) => item.productCode === productValue);
            setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, { ...newProduct, quantity: quantityValue, additionalOptionsValue: additionalOptionsValue }]);
        }
    }
    catch (error) {
        console.log(error)
    }
}


export const sendEmailToOwnerBulk = async (name, surname, email, phone, address, company, danocenBroj, setSelectedProducts, setError) => {
    try {

        if(name.trim() === '' || surname.trim() === '' || phone.trim() === '' || address.trim() === '' || company.trim() === '' || danocenBroj.trim() === '') {
            setError("Ве молиме потполнете ги сите полиња");
            return null;
        }
        setError('');
        const productsData = JSON.parse(localStorage.getItem("bulkCartItems"));
        const contentHTML = `
        <h1 style="text-align:center">Извршена е нова нарачка!</h1>
        <table style="width: 100%; border-collapse: collapse;">
            <thead>
            <tr style="background: #000; color: #fff; text-align:center">
                <th style="padding: 15px;">Име</th>
                <th style="padding: 15px;">Презиме</th>
                <th style="padding: 15px;">Е-Маил адреса</th>
                <th style="padding: 15px;">Телефонски број</th>
                <th style="padding: 15px;">Адреса</th>
                <th style="padding: 15px;">Име на компанија</th>
                <th style="padding: 15px;">Даночен број</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align:center">
                <td style="padding: 15px;">${name}</td>
                <td style="padding: 15px;">${surname}</td>
                <td style="padding: 15px;">${email}</td>
                <td style="padding: 15px;">${phone}</td>
                <td style="padding: 15px;">${address}</td>
                <td style="padding: 15px;">${company}</td>
                <td style="padding: 15px;">${danocenBroj}</td>
            </tr>
            </tbody>
        </table>
        <h3 style="text-align:center">Листа на нарачани продукти</h3>`;

        const productsTableHTML = `
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr style="background: #000; color: #fff">
                        <th style="padding: 15px;">Продукт МК</th>
                        <th style="padding: 15px;">Продукт ЕН</th>
                        <th style="padding: 15px;">Шифра</th>
                        <th style="padding: 15px;">Количина</th>
                        <th style="padding: 15px;">Број на продукт</th>
                    </tr>`;

        const generateTable = productsData.map((item) => {
            return `<tr style="text-align:center">
                        <td style="padding: 15px;">${item.productNameMk}</td>
                        <td style="padding: 15px;">${item.productNameEn}</td>
                        <td style="padding: 15px;">${item.productCode}</td>
                        <td style="padding: 15px;">${item.quantity}</td>
                        <td style="padding: 15px;">${item.additionalOptionsValue}</td>
                    </tr>`})

        const resultText = generateTable.join(' ');
        const finalText = contentHTML + (productsTableHTML + resultText + `</table>`);
        
        emailjs.init('MZN4ZQrZ7AZyUapGb');
        emailjs.send('service_kvwkjfi', 'template_pokjxub',{
            subject: `Известување: нова нарачка!`,
            html_content: finalText,
            html: true,
          })
          .then((response) => {
            console.log('Email sent:', response);
            localStorage.setItem("bulkCartItems", JSON.stringify([]));
            setSelectedProducts([]);
            successToast('Успешно испратено!');
          })
          .catch((error) => {
            console.error('Email could not be sent:', error);
            errorToast('Грешка во испраќањето!');
          });
    } catch (error) {
        console.log(error);
    }
}

export const sendEmailToOwnerCart = async (name, surname, email, phone, address, setCartItems, setError) => {
    try {
        if(name.trim() === '' || surname.trim() === '' || phone.trim() === '' || address.trim() === '') {
            setError("Ве молиме потполнете ги сите полиња");
            return null;
        }
        setError('');
        const productsData = JSON.parse(localStorage.getItem("cartItems"));
        const contentHTML = `
        <h1 style="text-align:center">Извршена е нова нарачка!</h1>
        <table style="width: 100%; border-collapse: collapse;">
            <thead>
            <tr style="background: #000; color: #fff; text-align:center">
                <th style="padding: 15px;">Име</th>
                <th style="padding: 15px;">Презиме</th>
                <th style="padding: 15px;">Е-Маил адреса</th>
                <th style="padding: 15px;">Телефонски број</th>
                <th style="padding: 15px;">Адреса</th>
            </tr>
            </thead>
            <tbody>
            <tr style="text-align:center">
                <td style="padding: 15px;">${name}</td>
                <td style="padding: 15px;">${surname}</td>
                <td style="padding: 15px;">${email}</td>
                <td style="padding: 15px;">${phone}</td>
                <td style="padding: 15px;">${address}</td>
            </tr>
            </tbody>
        </table>
        <h3 style="text-align:center">Листа на нарачани продукти</h3>`;

        const productsTableHTML = `
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr style="background: #000; color: #fff">
                        <th style="padding: 15px;">Продукт МК</th>
                        <th style="padding: 15px;">Продукт ЕН</th>
                        <th style="padding: 15px;">Шифра</th>
                        <th style="padding: 15px;">Количина</th>
                        <th style="padding: 15px;">Број на продукт</th>
                    </tr>`;

        const generateTable = productsData.map((item) => {
            return `<tr style="text-align:center">
                        <td style="padding: 15px;">${item.productNameMk}</td>
                        <td style="padding: 15px;">${item.productNameEn}</td>
                        <td style="padding: 15px;">${item.productCode}</td>
                        <td style="padding: 15px;">${item.quantity}</td>
                        <td style="padding: 15px;">${item.additionalOptionsValue}</td>
                    </tr>`})

        const resultText = generateTable.join(' ');
        const finalText = contentHTML + (productsTableHTML + resultText + `</table>`);
            
        emailjs.init('MZN4ZQrZ7AZyUapGb');
        emailjs.send('service_kvwkjfi', 'template_pokjxub',{
            subject: `Известување: нова нарачка!`,
            html_content: finalText,
            html: true,
          })
          .then((response) => {
            console.log('Email sent:', response);
            localStorage.setItem("cartItems", JSON.stringify([]));
            setCartItems([]);
            successToast('Успешно испратено!');
          })
          .catch((error) => {
            console.error('Email could not be sent:', error);
            errorToast('Грешка во испраќањето!');
          });
    } catch (error) {
        console.log(error);
    }
}


export const successToast = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
        });
}

export const errorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
        });
}