import { useContext } from "react";
import { deleteProductFromCart } from "../../Utils/App Functions/appFunctions";
import { AiOutlineDelete } from "react-icons/ai";
import { CartContext } from "../../Utils/useContext Hooks/CartContextComponent";


const CartTable = () => {

    const { cartItems, setCartItems } = useContext(CartContext);

    return (
        <>
        <div className="table-responsive">
            <table className="table align-baseline table-sm text-center">
                    <thead>
                    <tr>
                        <th scope="col" className="size-19">#</th>
                        <th scope="col"></th>
                        <th scope="col" className="size-19">Продукт</th>
                        <th scope="col" className="size-19">Единечна цена</th>
                        <th scope="col" className="size-19">Количина</th>
                        <th scope="col" className="size-19">Број на продукт</th>
                        <th scope="col" className="size-19">Цена</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        cartItems&&cartItems.map((item, index) => (
                            <tr key={item.productCode}>
                                <th scope="row">{index + 1}</th>
                                <td><img src={item.productImage} alt="" style={{width: '100px'}}/></td>
                                <td className="size-20">{item.productNameMk}</td>
                                <td className="size-20">{item.productPrice}</td>
                                <td className="size-20">{item.quantity}</td>
                                <td className="size-20">{item.additionalOptionsValue}</td>
                                <td className="size-20">{parseInt(item.productPrice.replace(/\D/g, '')) * item.quantity}</td>
                                <td><AiOutlineDelete onClick={() => deleteProductFromCart(item, cartItems, setCartItems)}/></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
        </div>
        </>
    )
}

export default CartTable;