import { AiOutlineDelete } from "react-icons/ai";
import { deleteProductFromCart } from "../../Utils/App Functions/appFunctions";

const BulkCartTable = ({selectedProducts, setSelectedProducts}) => {

  return (
    <>
      <div className="table-responsive">
        <table className="table align-baseline table-sm text-center">
          <thead>
            <tr>
              <th scope="col" className="size-19">
                #
              </th>
              <th scope="col"></th>
              <th scope="col" className="size-19">
                Име на продукт
              </th>
              <th scope="col" className="size-19">
                Количина
              </th>
              <th scope="col">Број на продукт</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts &&
              selectedProducts.map((item, index) => (
                <tr key={item.productCode}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <img
                      src={item.productImage}
                      alt=""
                      style={{ width: "100px" }}
                    />
                  </td>
                  <td className="size-20">{item.productNameMk}</td>
                  <td className="size-20">{item.quantity}</td>
                  <td className="size-20">{item.additionalOptionsValue}</td>
                  <td>
                    <AiOutlineDelete
                      onClick={() =>
                        deleteProductFromCart(
                          item,
                          selectedProducts,
                          setSelectedProducts
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BulkCartTable;
