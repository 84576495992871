import { testimonialsList } from "../../Utils/Testimonials Config/testimonials-config";

import './testimonialssectionstyle.css';

const TestimonialsSection = () => {
    return (
        <>
            <section className="testimonials-section pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className='title-text fw-bold mt-3' style={{fontSize: '2rem'}}>Нашите клиенти - кажуваат за нас</h1>
                        </div>
                    </div>

                    <div className="row gap-5 justify-content-center mt-5">
                        {
                            testimonialsList.map((item) => (
                                <div className="col-md-5 testimonials-card d-flex">
                                    <div className="testimonials-image-wrapper">
                                        <img src={item.image} alt=""/>
                                    </div>
                                    
                                    <div className="testimonials-info-wrapper">
                                        <p className="mb-3 size-18">{item.description}</p>
                                        <h6>{item.name}</h6>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>

    )
}

export default TestimonialsSection;