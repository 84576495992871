import { useNavigate } from 'react-router';
import Button from '../Button/Button';
import './HomeTopSectionStyle.css';
import mobileImage from '../../Assets/Images/HomeTopSectionBackground.jpg';

const HomeTopSection = () => {

    const navigate = useNavigate();

    return (
        <>
           <section className='home-top-section container-fluid'>
                <div className='row'>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'>
                        <h1 className='title-text fw-bold mt-2 title-home-top'>SELECTIVE PROFESSIONAL MK</h1>
                        <p className="size-18">Италијанска професионална козметика за коса</p>
                        <Button text={"Нарачај големо"} classes="mt-4" onClick={() => navigate('/bulk-cart')}/>
                    </div>
                </div>
           </section>

           <section className='mobile-home-top-section container-fluid'>
                <div className='row flex-column'>
                    <div className='col-md-12'>
                        <img src={mobileImage} alt='' className='w-100'/>
                    </div>
                    <div className='col-md-12 pt-3 pb-3'>
                        <h1 className='title-text fw-bold mt-2 title-home-top'>SELECTIVE PROFESSIONAL MK</h1>
                        <p className="size-18">Италијанска професионална козметика за коса</p>
                        <Button text={"Нарачај големо"} classes="mt-4" onClick={() => navigate('/bulk-cart')}/>
                    </div>
                </div>
           </section>
        </>
    )
}

export default HomeTopSection;