import Navigation from "../../Components/Navigation/Navigation";
import topImage from '../../Assets/Images/about-page-top.jpg';
import Footer from "../../Components/Footer/Footer";

const AboutUsPage = () => {
    return (
        <>
            <Navigation/>
            <section className="product-page-top-section">
                <img src={topImage} alt="" className="w-100"></img>
            </section>

            <section className="pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Кои сме ние?</h2>
                            <p className="size-20 mt-3">Еклузивен италијански бренд, 100% произведен во Италија со над 30 години искуство. Интернационална дистрибуција во 70 земји,
                                способни да го понудат ритамот на решенијата за убавина во однос на квалитетот,
                                ефикасноста и безбедноста на професионалниот пазар.
                            </p>
                            <h2 className="mt-5">Што правиме ние?</h2>
                            <p className="size-20 mt-3">Од боја до нега и стајлинг, Selective Professional нуди целосен
                            опсег на екслузивни професионални производи дизајнирани успешно да ги задоволат глобалните и локалните потребни и желби.
                            </p>
                            <h2 className="mt-5">Зошто да не одберете нас?</h2>
                            <p className="size-20 mt-3">Selective Professional има долга традиција и експертиза во професионалност,
                            со многу силен фокус на боите и техничките услуги.
                            Гаранција за сите фризери, ги подржува и промовира вредностите како што се ефикасност, безбедност и доверливост.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default AboutUsPage;