import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import SingleCategoryPage from './Pages/Single Category Page/SingleCategoryPage';
import SubCategoryPage from './Pages/Sub Category Page/SubCategoryPage';
import ProductPage from './Pages/Product Page/ProductPage';
import CartContextComponent from './Utils/useContext Hooks/CartContextComponent';
import CartPage from './Pages/Cart Page/CartPage';
import BulkCartPage from './Pages/Bulk Cart Page/BulkCartPage';
import AboutUsPage from './Pages/About Us Page/AboutUsPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/category/:categoryname/:id",
    element: <SingleCategoryPage/>,
  },
  {
    path: "/category/:categoryname/:id/:subcategoryname/:id",
    element: <SubCategoryPage/>
  },
  {
    path: "/category/:categoryname/:categoryid/:subcategoryname/:subcategoryid/:productname/:productid",
    element: <ProductPage/>
  },
  {
    path: "/cart",
    element: <CartPage/>
  },
  {
    path: "/bulk-cart",
    element: <BulkCartPage/>
  },
  {
    path: "/about-us",
    element: <AboutUsPage/>
  }
]);


root.render(
  <React.StrictMode>
   
  <CartContextComponent><RouterProvider router={router} /></CartContextComponent>
   
  </React.StrictMode>
);
