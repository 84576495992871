import image1 from '../../Assets/Images/Testimonials Images/testimonials-1.png';
import image2 from '../../Assets/Images/Testimonials Images/testimonials-2.png';
import image3 from '../../Assets/Images/Testimonials Images/testimonials-3.jpg';
import image4 from '../../Assets/Images/Testimonials Images/testimonials-4.jpg';

export const testimonialsList = [
    {
        id: 1,
        image: image1,
        name: `Марија Стојановска`,
        description: `Шапонот, кондиционерот и млекото за измазнување од Smooth линијата ги користам заедно и не ми се потребни капки за коса затоа што заедно ми даваат мазен ефект и сјај на косата и лесно се расчешлува.Ги препорачувам доколку имате долга и права коса.`
    },
    {
        id: 2,
        image: image2,
        name: `Виолета Кузмановска`,
        description: `Кератинската маска е сон, преубаво ја негува косата, ако снемам и набрзина земам друга, не е тоа тоа и секогаш се навраќам на оваа.
        Секогаш земам и двофазниот кондиционер, многу лесно се расчешлува косата со него и ја прави сјајна и мека.
        Секогаш после фенирање користам од капките light on.
        За мене оваа коминација е совршен спој`
    },
    {
        id: 3,
        image: image3,
        name: `Софија Александровска`,
        description: `Colorevo боите само што ги пробав и веќе не ги менувам, лесно се нанесуваат, потребно е помалку боја од вообичаено, имаат пријатен мирис, по миењето на бојата имаш чувство какп да си ставила маска за коса. За покривност на седи и трајност се идеални`
    },
    {
        id: 4,
        image: image4,
        name: `Марија Ивановска`,
        description: `Color block линијата  е за мене идеална комбинација за трајност на бојата на косата, а истовремено и длабински ја хидрира, ја прави сјајна и здрава.`
    },
]