import HomePage from "./Pages/Home Page/HomePage";
import './Assets/Style/App.css';

function App() {
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
