import './buttonstyle.css';

const Button = ({text, classes, onClick}) => {
    return (
        <>
            <button className={`button ${classes}`} type="button" onClick={onClick}>{text}</button>
        </>
    )
}

export default Button;