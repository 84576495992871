import Navigation from "../../Components/Navigation/Navigation";
import topSectionImage from "../../Assets/Images/cart-page-top.jpg";
import dataConfig from "../../Utils/Data/data-config";
import { useEffect, useRef, useState } from "react";
import Button from "../../Components/Button/Button";
import { AddToBulkCartAction } from "../../Utils/App Functions/appFunctions";
import BulkCartTable from "../../Components/Bulk Cart Table/BulkCartTable";
import EmptyCart from "../../Components/Empty Cart/EmptyCart";
import BulkCartForm from "../../Components/Bulk Cart Form/BulkCartForm";
import Footer from "../../Components/Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BulkCartPage = () => {

    const [localStorageItems, setLocalStorageItems] = useState(JSON.parse(localStorage.getItem("bulkCartItems")));
    const [additionalOptions, setAdditionalOptions] = useState(true);
    const [allProducts, setAllProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(localStorageItems === null || localStorageItems === "" ? [] : localStorageItems);
    const quantityRef = useRef();
    const selectRef = useRef();
    const additionalOptionsRef = useRef();
    const [error, setError] = useState();

    useEffect(() => {
        const products = dataConfig.flatMap((category) =>
            category.subCategory.flatMap((subcategory) => subcategory.products)
            );
        setAllProducts(products);
    }, [])

    useEffect(() => {
        localStorage.setItem("bulkCartItems", JSON.stringify(selectedProducts));
    }, [selectedProducts]);

    const handleAdditionalOptions = () => {
        const productHasAO = allProducts.find((item) => item.productCode === selectRef.current.value);
        productHasAO.additionalOptions === true ? setAdditionalOptions(true) : setAdditionalOptions(false);
    }

    return (
        <>  
            <Navigation/>
            <section className="product-page-top-section">
                <img src={topSectionImage} alt="" className="w-100"></img>
            </section>
            <section className="product-page-product-container pt-5 pb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h1 className="text-title text-center">Нарачај големо</h1>
                        </div>

                        <div className="col-md-12">
                            <div className="bulk-form-container pt-5">
                                <form>
                                <div className="mb-3">
                                    <label htmlFor="selectInput" className="form-label size-18 fw-bold">Одберете продукт</label>
                                    <select id="selectInput" className="form-select size-18" ref={selectRef} onChange={handleAdditionalOptions}>
                                        {/* <option className="size-18">Сите продукти</option> */}
                                        {
                                            allProducts.map((item) => (
                                                <option key={item.productCode} className="size-18" value={item.productCode}>{item.productNameMk}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="selectInput" className="form-label size-18 fw-bold">Внесете количина</label>
                                    <input type="text" className="form-control size-18" ref={quantityRef}></input>
                                </div>
                                {
                                    additionalOptions? <div className="mb-3">
                                    <label htmlFor="selectInput" className="form-label size-18 fw-bold">Внесете број на продукт</label>
                                    <input type="text" className="form-control size-18" ref={additionalOptionsRef}></input>
                                </div> : ``
                                }
                                <div className="mb-3">
                                    {
                                        error ? <p className="text-danger size-18 pt-3 pb-3 fw-bold">{error}</p> : ``
                                    }
                                    
                                    <Button text="Внесете продукт" onClick={() => AddToBulkCartAction(quantityRef, selectRef, selectedProducts, setSelectedProducts, allProducts, setError, additionalOptionsRef)}/>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-12">
                        {
                            selectedProducts.length === 0 || selectedProducts === null ?
                            <EmptyCart/> :
                            <>
                            <h2 className="text-center pb-5">Вашите продукти</h2>
                            <BulkCartTable selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                            </>
                        }
                    </div>
                </div>
            </section>

            <section className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-12">
                        {
                            selectedProducts.length === 0 || selectedProducts === null ?
                            `` :
                            <>
                            <h2 className="text-center pb-5">Внесете ги вашите информации</h2>
                            <BulkCartForm setSelectedProducts={setSelectedProducts}/>
                            </>
                        }
                    </div>
                </div>
            </section>
            <Footer/>
            <ToastContainer />
            </>
    )
}

export default BulkCartPage;