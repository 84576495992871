import './productcardstyle.css';

const ProductCard = ({props}) => {

  return (
    <>
      <div className="product-card text-center">
        <div className="product-card-image-wrapper">
          <img src={props.productImage ? props.productImage : props} alt="" className="w-100" />
        </div>

        <div className="product-card-info-wrapper mt-4">
          <h3 className='fw-bold'>{props.productNameMk}</h3>
          <span className='d-inline-block mt-2'>{props.productPrice}</span>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
