import Footer from "../../Components/Footer/Footer";
import Navigation from "../../Components/Navigation/Navigation";
import topSectionImage from "../../Assets/Images/cart-page-top.jpg";
import { useContext } from "react";
import { CartContext } from "../../Utils/useContext Hooks/CartContextComponent";
import './cartpagestyle.css';
import CartTable from "../../Components/CartTable/CartTable";
import OrderInformation from "../../Components/Order Information/OrderInformation";
import CartFormMalo from "../../Components/Cart Form Malo/CartFormMalo";
import EmptyCart from "../../Components/Empty Cart/EmptyCart";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CartPage = () => {
 
  const { cartItems, setCartItems } = useContext(CartContext);

  return (
    <>
      <Navigation />
      <section className="product-page-top-section">
        <img src={topSectionImage} alt="" className="w-100"></img>
      </section>

      <section className="product-page-product-container pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
                {
                  cartItems.length === 0 ?
                  <EmptyCart/> : 
                  <>
                    <h2 className="text-center pb-5">Кошничка</h2>
                    <CartTable/>
                  </>
                }
            </div>
          </div>
        </div>
      </section>

      {
        cartItems.length === 0 ? `` : <section className="pt-5 pb-5">
        <div className="container">
            <div className="row">
                <h2 className="text-center pb-5">Внесете информации за достава</h2>
                <div className="col-md-6">
                    <CartFormMalo/>
                </div>

                <div className="col-md-6">
                  <OrderInformation/>
                </div>
            </div>
        </div>
      </section>
      }
      <Footer />
      <ToastContainer />
    </>
  );
};

export default CartPage;
