import Button from "../Button/Button";
import './productspotlightstyle.css';
import image from '../../Assets/Images/colorevo_oxy.png';
import { useNavigate } from "react-router";

const ProductSpotlight = () => {

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/category/technical-products/1/colorevo/1');
  }

  return (
    <>
      <section className="pt-5 pb-5 product-spotlight-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
                <div className="product-spotlight-image-wrapper">
                    <img src={image} alt="" className="w-75"/>
                </div>
            </div>

            <div className="col-md-8">
                <div className="product-spotlight-info-wrapper px-5">
                    <h1>Здравата коса е симбол на нашата убавина</h1>
                    <p className="grey-color mt-3 size-19">
                    Откријте нашиот најнов продукт за коса што го револуционира начинот на кој се грижите за вашата коса. Здрава и сјајна коса во секој чекор.
                    Нашиот продукт наскоро ќе стане дел од вашата дневна рутина. Откријте го нашиот продукт за коса, што ќе ви ја приближи вашата коса до перфекција.
                    Со нашиот продукт за коса, секој ден станува прекрасен. Дојдете и го искусете сами.
                    </p>
                    <Button text="Повеќе" classes="mt-4" onClick={handleRedirect}/>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductSpotlight;
